import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";

import PropTypes from "prop-types"; // ES6

import { bindActionCreators } from 'redux';
import { connect } from "react-redux";

import { getOrdersQty, getDashboardQty } from './reducers/storedata/storedataActions';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getOrdersQty, getDashboardQty }, dispatch)
}

class App extends Component {

  static propTypes = {
    getOrdersQty: PropTypes.func,
    getDashboardQty: PropTypes.func,
  };

  componentDidMount(){
    console.log("componentDidMount");
    this.props.getOrdersQty();
    this.props.getDashboardQty();
  }

  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default connect(null, mapDispatchToProps)(App);
