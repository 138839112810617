import { RootState } from '../config/configureStore'

export const Auth = (state: RootState) => state.auth || undefined
export const Device = (state: RootState) => state.device || undefined
export const StoreData = (state: RootState) => state.storedata || undefined

export const isFetching = (state: RootState) => state.storedata.isFetching || undefined
export const StoreDataError = (state: RootState) => state.storedata.error || undefined
export const StoreDataSuccess = (state: RootState) => state.storedata.success || undefined

export const authIsFetching = (state: RootState) => state.auth.isFetching || undefined
export const AuthError = (state: RootState) => state.auth.error || undefined
export const AuthSuccess = (state: RootState) => state.auth.success || undefined

export const UserInfo = (state: RootState) => state.auth.userInfo || undefined

export const Users = (state: RootState) => state.auth.users || []
export const usersFiltered = (state: RootState) => state.auth.usersFiltered || []
export const lastUsersPage = (state: RootState) => state.auth.lastUsersPage || 0
export const totalUsersResultRows = (state: RootState) => state.auth.totalUsersResultRows || 0
export const totalUsersRows = (state: RootState) => state.auth.totalUsersRows || 0
export const entriesPerPageUsersRows = (state: RootState) => state.auth.entriesPerPageUsersRows || 0

export const TimeSlots = (state: RootState) => state.storedata.timeSlots || []
export const timeSlotsFiltered = (state: RootState) => state.storedata.timeSlotsFiltered || []
export const lastTimeSlotPage = (state: RootState) => state.storedata.lastTimeSlotPage || 0
export const totalTimeSlotResultRows = (state: RootState) =>
  state.storedata.totalTimeSlotResultRows || 0
export const totalTimeSlotRows = (state: RootState) => state.storedata.totalTimeSlotRows || 0
export const entriesPerPageTimeSlotRows = (state: RootState) =>
  state.storedata.entriesPerPageTimeSlotRows || 0

export const PaymentMethods = (state: RootState) => state.storedata.paymentMethods || []
export const paymentMethodsFiltered = (state: RootState) =>
  state.storedata.paymentMethodsFiltered || []
export const lastPaymentMethodPage = (state: RootState) =>
  state.storedata.lastPaymentMethodPage || 0
export const totalPaymentMethodResultRows = (state: RootState) =>
  state.storedata.totalPaymentMethodResultRows || 0
export const totalPaymentMethodRows = (state: RootState) =>
  state.storedata.totalPaymentMethodRows || 0
export const entriesPerPagePaymentMethodRows = (state: RootState) =>
  state.storedata.entriesPerPagePaymentMethodRows || 0

export const DeliveryVariants = (state: RootState) => state.storedata.deliveryVariants || []
export const deliveryVariantsFiltered = (state: RootState) =>
  state.storedata.deliveryVariantsFiltered || []
export const lastDeliveryVariantPage = (state: RootState) =>
  state.storedata.lastDeliveryVariantPage || 0
export const totalDeliveryVariantResultRows = (state: RootState) =>
  state.storedata.totalDeliveryVariantResultRows || 0
export const totalDeliveryVariantRows = (state: RootState) =>
  state.storedata.totalDeliveryVariantRows || 0
export const entriesPerPageDeliveryVariantRows = (state: RootState) =>
  state.storedata.entriesPerPageDeliveryVariantRows || 0

export const PriceTypes = (state: RootState) => state.storedata.priceTypes || []
export const priceTypesFiltered = (state: RootState) => state.storedata.priceTypesFiltered || []
export const lastPriceTypePage = (state: RootState) => state.storedata.lastPriceTypePage || 0
export const totalPriceTypeResultRows = (state: RootState) =>
  state.storedata.totalPriceTypeResultRows || 0
export const totalPriceTypeRows = (state: RootState) => state.storedata.totalPriceTypeRows || 0
export const entriesPerPagePriceTypeRows = (state: RootState) =>
  state.storedata.entriesPerPagePriceTypeRows || 0

export const Orders = (state: RootState) => state.storedata.orders || []
export const ordersFiltered = (state: RootState) => state.storedata.ordersFiltered || []
export const lastOrderPage = (state: RootState) => state.storedata.lastOrderPage || 0
export const totalOrderResultRows = (state: RootState) => state.storedata.totalOrderResultRows || 0
export const totalOrderRows = (state: RootState) => state.storedata.totalOrderRows || 0
export const entriesPerPageOrderRows = (state: RootState) =>
  state.storedata.entriesPerPageOrderRows || 0
export const NewOrdersQty = (state: RootState) => state.storedata.ordersQty || 0

export const OrderStatus = (state: RootState) => state.storedata.orderStatus || []

export const Warehouses = (state: RootState) => state.storedata.warehouses || []

export const AreasDropDownItems = (state: RootState) => state.storedata.areasDropDownItems || []

export const groupsAllFiltered = (state: RootState) => state.storedata.allGroupsFiltered || []
export const usersAllFiltered = (state: RootState) => state.storedata.allUsersFiltered || []
export const priceTypesAllFiltered = (state: RootState) => state.storedata.allPriceTypesFiltered || []

export const goodsFiltered = (state: RootState) => state.storedata.goodsFiltered || []
export const lastGoodPage = (state: RootState) => state.storedata.lastGoodPage || 0
export const totalGoodResultRows = (state: RootState) => state.storedata.totalGoodResultRows || 0
export const totalGoodRows = (state: RootState) => state.storedata.totalGoodRows || 0
export const entriesPerPageGoodRows = (state: RootState) =>
  state.storedata.entriesPerPageGoodRows || 0

export const SelectedGroup = (state: RootState) => state.storedata.selectedGroup || undefined
export const SelectedMessageUser = (state: RootState) =>
  state.storedata.selectedMessageUser || undefined
export const SelectedChatUser = (state: RootState) => state.storedata.selectedChatUser || undefined
export const SelectedStatus = (state: RootState) => state.storedata.selectedStatus || undefined

export const groupsFiltered = (state: RootState) => state.storedata.groupsFiltered || []
export const lastGroupPage = (state: RootState) => state.storedata.lastGroupPage || 0
export const totalGroupResultRows = (state: RootState) => state.storedata.totalGroupResultRows || 0
export const totalGroupRows = (state: RootState) => state.storedata.totalGroupRows || 0
export const entriesPerPageGroupRows = (state: RootState) =>
  state.storedata.entriesPerPageGroupRows || 0

export const ExclusiveGoodsFiltered = (state: RootState) =>
  state.storedata.exclusiveGoodsFiltered || []
export const BestsellingGoodsFiltered = (state: RootState) =>
  state.storedata.bestsellingGoodsFiltered || []
export const GroceriesGoodsFiltered = (state: RootState) =>
  state.storedata.groceriesGoodsFiltered || []

export const GoodsDetails = (state: RootState) => state.storedata.goodsDetails || undefined

// export const qtyInCart = (state: RootState) => {
//   let total: number = 0;
//   Object.values(state.storedata.myCart).forEach(o => {
//     if (o.qty !== 0) {
//       total += 1;
//     }
//   });
//   return total;
// };

// export const sumInCart = (state: RootState) => {
//   let total: number = 0;
//   Object.values(state.storedata.myCart).forEach(o => {
//     if (o.qty !== 0) {
//       total += o.qty * o.price;
//     }
//   });
//   return total;
// };

export const MyCart = (state: RootState) => state.storedata.myCart || []

export const MyFavorites = (state: RootState) => state.storedata.myFavorites || []

export const MyOrders = (state: RootState) => state.storedata.myOrders || []

export const lastCartPage = (state: RootState) => state.storedata.lastCartPage || 0
export const lastFavoritesPage = (state: RootState) => state.storedata.lastFavoritesPage || 0
export const lastOrdersPage = (state: RootState) => state.storedata.lastOrdersPage || 0

export const MySearchGoodsFiltered = (state: RootState) =>
  state.storedata.mySearchGoodsFiltered || []

export const lastSearchGoodsPage = (state: RootState) => state.storedata.lastSearchGoodsPage || 0

export const Messages = (state: RootState) => state.storedata.messagesFiltered || []
// export const qtyUnreadMessages = (state: RootState) => {
//   let total: number = 0;
//   Object.values(state.storedata.messagesFiltered).forEach(o => {
//     if (o.active === true) {
//       total += 1;
//     }
//   });
//   return total;
// };
export const MessagesFiltered = (state: RootState) => state.storedata.messagesFiltered || []
export const lastMessagesPage = (state: RootState) => state.storedata.lastMessagesPage || 0
export const totalMessagesResultRows = (state: RootState) =>
  state.storedata.totalMessagesResultRows || 0
export const totalMessagesRows = (state: RootState) => state.storedata.totalMessagesRows || 0
export const entriesPerPageMessagesRows = (state: RootState) =>
  state.storedata.entriesPerPageMessagesRows || 0

export const ChatFiltered = (state: RootState) => state.storedata.chatFiltered || []
export const lastChatPage = (state: RootState) => state.storedata.lastChatPage || 0
// export const qtyUnreadChat = (state: RootState) => {
//   let total: number = 0;
//   Object.values(state.storedata.chat).forEach(o => {
//     if (o.active === true) {
//       total += 1;
//     }
//   });
//   return total;
// };
export const totalChatResultRows = (state: RootState) => state.storedata.totalChatResultRows || 0
export const totalChatRows = (state: RootState) => state.storedata.totalChatRows || 0
export const entriesPerPageChatRows = (state: RootState) =>
  state.storedata.entriesPerPageChatRows || 0

// pgstore admin
export const SidebarShow = (state: RootState) => state.device.sidebarShow

export const AvatarIcon = (state: RootState) => state.auth.AvatarIcon
export const CreatedUser = (state: RootState) => state.auth.createdUser
export const SignupForm = (state: RootState) => state.auth.form // isValid (form) error information

// Dashboard
export const Total_num_orders = (state: RootState) => state.storedata.total_num_orders || 0
export const Total_num_goods = (state: RootState) => state.storedata.total_num_goods || 0
export const Total_num_users = (state: RootState) => state.storedata.total_num_users || 0
export const Total_num_in_carts = (state: RootState) => state.storedata.total_num_in_carts || 0


export const PricelistsByGood = (state: RootState) => state.storedata.pricelistsByGood || []

export const ImageListPaths = (state: RootState) => state.storedata.imageListPaths || []

export const BannersFiltered = (state: RootState) => state.storedata.bannersFiltered || []
export const lastBannersPage = (state: RootState) => state.storedata.lastBannersPage || 0
export const totalBannersResultRows = (state: RootState) =>
  state.storedata.totalBannersResultRows || 0
export const totalBannersRows = (state: RootState) => state.storedata.totalBannersRows || 0
export const entriesPerPageBannersRows = (state: RootState) =>
  state.storedata.entriesPerPageBannersRows || 0