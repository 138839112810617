import keyMirror from "key-mirror";

export default keyMirror({
  SET_PLATFORM: null,
  SET_VERSION: null,

  LOGOUT: null,
  FORGOT_PASSWORD: null,
  LOGIN: null,
  REGISTER: null,
  VERIFY_EMAIL: null,
  SIGNUP_SOCIAL: null,
  SELECT_LOCATION: null,

  ON_AUTH_FORM_FIELD_CHANGE: null,
  SIGNUP_REQUEST: null,
  SIGNUP_SUCCESS: null,
  SIGNUP_FAILURE: null,

  SIGNUP_SOCIAL_REQUEST: null,
  SIGNUP_SOCIAL_SUCCESS: null,
  SIGNUP_SOCIAL_FAILURE: null,

  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  RESET_PASSWORD_REQUEST: null,
  RESET_PASSWORD_SUCCESS: null,
  RESET_PASSWORD_FAILURE: null,

  VERIFY_EMAIL_REQUEST: null,
  VERIFY_EMAIL_SUCCESS: null,
  VERIFY_EMAIL_FAILURE: null,

  GET_PROFILE_REQUEST: null,
  GET_PROFILE_SUCCESS: null,
  GET_PROFILE_FAILURE: null,

  ON_PROFILE_FORM_FIELD_CHANGE: null,

  PROFILE_UPDATE_REQUEST: null,
  PROFILE_UPDATE_SUCCESS: null,
  PROFILE_UPDATE_FAILURE: null,

  SET_STATE: null,
  GET_STATE: null,
  SET_STORE: null,

  CHECK_OR_CREATE_NEW_USER_REQUEST: null,
  CHECK_OR_CREATE_NEW_USER_SUCCESS: null,
  CHECK_OR_CREATE_NEW_USER_FAILURE: null,

  GET_USERS_REQUEST: null,
  GET_USERS_SUCCESS: null,
  GET_USERS_FAILURE: null,

  GET_USER_INFO_REQUEST: null,
  GET_USER_INFO_SUCCESS: null,
  GET_USER_INFO_FAILURE: null,

  SET_USER_INFO_REQUEST: null,
  SET_USER_INFO_SUCCESS: null,
  SET_USER_INFO_FAILURE: null,

  DELETE_USER_REQUEST: null,
  DELETE_USER_SUCCESS: null,
  DELETE_USER_FAILURE: null,

  DELETE_USER_DATA_REQUEST: null,
  DELETE_USER_DATA_SUCCESS: null,
  DELETE_USER_DATA_FAILURE: null,

  GET_AREAS_REQUEST: null,
  GET_AREAS_SUCCESS: null,
  GET_AREAS_FAILURE: null,

  GET_WAREHOUSES_REQUEST: null,
  GET_WAREHOUSES_SUCCESS: null,
  GET_WAREHOUSES_FAILURE: null,

  GET_TIME_SLOTS_REQUEST: null,
  GET_TIME_SLOTS_SUCCESS: null,
  GET_TIME_SLOTS_FAILURE: null,

  SET_TIME_SLOTS_REQUEST: null,
  SET_TIME_SLOTS_SUCCESS: null,
  SET_TIME_SLOTS_FAILURE: null,

  ADD_TIME_SLOTS_REQUEST: null,
  ADD_TIME_SLOTS_SUCCESS: null,
  ADD_TIME_SLOTS_FAILURE: null,

  GET_PAYMENT_METHODS_REQUEST: null,
  GET_PAYMENT_METHODS_SUCCESS: null,
  GET_PAYMENT_METHODS_FAILURE: null,

  SET_PAYMENT_METHODS_REQUEST: null,
  SET_PAYMENT_METHODS_SUCCESS: null,
  SET_PAYMENT_METHODS_FAILURE: null,

  ADD_PAYMENT_METHODS_REQUEST: null,
  ADD_PAYMENT_METHODS_SUCCESS: null,
  ADD_PAYMENT_METHODS_FAILURE: null,

  GET_DELIVERY_VARIANTS_REQUEST: null,
  GET_DELIVERY_VARIANTS_SUCCESS: null,
  GET_DELIVERY_VARIANTS_FAILURE: null,

  SET_DELIVERY_VARIANTS_REQUEST: null,
  SET_DELIVERY_VARIANTS_SUCCESS: null,
  SET_DELIVERY_VARIANTS_FAILURE: null,

  ADD_DELIVERY_VARIANTS_REQUEST: null,
  ADD_DELIVERY_VARIANTS_SUCCESS: null,
  ADD_DELIVERY_VARIANTS_FAILURE: null,

  GET_PRICE_TYPES_REQUEST: null,
  GET_PRICE_TYPES_SUCCESS: null,
  GET_PRICE_TYPES_FAILURE: null,

  SET_PRICE_TYPES_REQUEST: null,
  SET_PRICE_TYPES_SUCCESS: null,
  SET_PRICE_TYPES_FAILURE: null,

  ADD_PRICE_TYPES_REQUEST: null,
  ADD_PRICE_TYPES_SUCCESS: null,
  ADD_PRICE_TYPES_FAILURE: null,

  SAVE_AREA_AND_WAREHOUSE_REQUEST: null,
  SAVE_AREA_AND_WAREHOUSE_SUCCESS: null,
  SAVE_AREA_AND_WAREHOUSE_FAILURE: null,

  GET_ALL_GROUPS_REQUEST: null,
  GET_ALL_GROUPS_SUCCESS: null,
  GET_ALL_GROUPS_FAILURE: null,

  GET_ALL_USERS_REQUEST: null,
  GET_ALL_USERS_SUCCESS: null,
  GET_ALL_USERS_FAILURE: null,

  GET_ALL_PRICE_TYPES_REQUEST: null,
  GET_ALL_PRICE_TYPES_SUCCESS: null,
  GET_ALL_PRICE_TYPES_FAILURE: null,

  GET_GOODS_REQUEST: null,
  GET_GOODS_SUCCESS: null,
  GET_GOODS_FAILURE: null,

  SET_GOODS_REQUEST: null,
  SET_GOODS_SUCCESS: null,
  SET_GOODS_FAILURE: null,

  ADD_GOODS_REQUEST: null,
  ADD_GOODS_SUCCESS: null,
  ADD_GOODS_FAILURE: null,

  GET_GOODS_DETAILS_REQUEST: null,
  GET_GOODS_DETAILS_SUCCESS: null,
  GET_GOODS_DETAILS_FAILURE: null,

  GET_GROUPS_REQUEST: null,
  GET_GROUPS_SUCCESS: null,
  GET_GROUPS_FAILURE: null,

  SET_GROUPS_REQUEST: null,
  SET_GROUPS_SUCCESS: null,
  SET_GROUPS_FAILURE: null,

  ADD_GROUPS_REQUEST: null,
  ADD_GROUPS_SUCCESS: null,
  ADD_GROUPS_FAILURE: null,

  GET_GROUPS_DETAILS_REQUEST: null,
  GET_GROUPS_DETAILS_SUCCESS: null,
  GET_GROUPS_DETAILS_FAILURE: null,

  GET_MY_CART_REQUEST: null,
  GET_MY_CART_SUCCESS: null,
  GET_MY_CART_FAILURE: null,

  ADD_TO_CART_REQUEST: null,
  ADD_TO_CART_SUCCESS: null,
  ADD_TO_CART_FAILURE: null,

  GET_FAVORITES_REQUEST: null,
  GET_FAVORITES_SUCCESS: null,
  GET_FAVORITES_FAILURE: null,

  ADD_TO_FAVORITES_REQUEST: null,
  ADD_TO_FAVORITES_SUCCESS: null,
  ADD_TO_FAVORITES_FAILURE: null,

  ADD_TO_ORDERS_REQUEST: null,
  ADD_TO_ORDERS_SUCCESS: null,
  ADD_TO_ORDERS_FAILURE: null,

  GET_ORDERS_REQUEST: null,
  GET_ORDERS_SUCCESS: null,
  GET_ORDERS_FAILURE: null,

  GET_ORDERS_QTY_REQUEST: null,
  GET_ORDERS_QTY_SUCCESS: null,
  GET_ORDERS_QTY_FAILURE: null,

  SET_ORDER_STATUS_REQUEST: null,
  SET_ORDER_STATUS_SUCCESS: null,
  SET_ORDER_STATUS_FAILURE: null,

  SEARCH_GOODS_REQUEST: null,
  SEARCH_GOODS_SUCCESS: null,
  SEARCH_GOODS_FAILURE: null,

  LOGOUT_CLEAR_REQUEST: null,
  LOGOUT_CLEAR_SUCCESS: null,
  LOGOUT_CLEAR_FAILURE: null,

  GET_MESSAGES_REQUEST: null,
  GET_MESSAGES_SUCCESS: null,
  GET_MESSAGES_FAILURE: null,

  SET_MESSAGE_REQUEST: null,
  SET_MESSAGE_SUCCESS: null,
  SET_MESSAGE_FAILURE: null,

  ADD_MESSAGE_REQUEST: null,
  ADD_MESSAGE_SUCCESS: null,
  ADD_MESSAGE_FAILURE: null,

  GET_CHAT_REQUEST: null,
  GET_CHAT_SUCCESS: null,
  GET_CHAT_FAILURE: null,

  SET_CHAT_READ_REQUEST: null,
  SET_CHAT_READ_SUCCESS: null,
  SET_CHAT_READ_FAILURE: null,

  ADD_TO_CHAT_REQUEST: null,
  ADD_TO_CHAT_SUCCESS: null,
  ADD_TO_CHAT_FAILURE: null,

  SET_CHAT_REQUEST: null,
  SET_CHAT_SUCCESS: null,
  SET_CHAT_FAILURE: null,

  GET_DASHBOARD_QTY_REQUEST: null,
  GET_DASHBOARD_QTY_SUCCESS: null,
  GET_DASHBOARD_QTY_FAILURE: null,

  GET_PRICELISTS_BY_GOOD_ID_REQUEST: null,
  GET_PRICELISTS_BY_GOOD_ID_SUCCESS: null,
  GET_PRICELISTS_BY_GOOD_ID_FAILURE: null,

  SET_UPLOAD_IMAGE_REQUEST: null,
  SET_UPLOAD_IMAGE_SUCCESS: null,
  SET_UPLOAD_IMAGE_FAILURE: null,

  GET_BANNERS_REQUEST: null,
  GET_BANNERS_SUCCESS: null,
  GET_BANNERS_FAILURE: null,

  SET_BANNER_REQUEST: null,
  SET_BANNER_SUCCESS: null,
  SET_BANNER_FAILURE: null,

  ADD_BANNER_REQUEST: null,
  ADD_BANNER_SUCCESS: null,
  ADD_BANNER_FAILURE: null,

  // pgstore admin

  SET_SIDEBAR_SHOW: null,

  //-----
});
