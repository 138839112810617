/**
 * # deviceInitialState.js
 *
 * This class is a Immutable object
 * Working *successfully* with Redux, requires
 * state that is immutable.
 * In my opinion, that can not be by convention
 * By using Immutable, it's enforced.  Just saying....
 *
 */
"use strict";

/**
 * ## Import immutable record
 */
import { Record } from "immutable";
import pack from "../../../package.json";

const platform = "web";
/**
 *  The version of the app but not  displayed yet
 */
var VERSION = pack.version;

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
var InitialState = Record({
  isMobile: true,
  platform: platform,
  version: VERSION,
  uniqueID: "", // device uniqueID

  sidebarShow: false,
});

export default InitialState;
