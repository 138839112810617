/**
 * # storedataInitialState.js
 *
 * This class is a Immutable object
 * Working *successfully* with Redux, requires
 * state that is immutable.
 * In my opinion, that can not be by convention
 * By using Immutable, it's enforced.  Just saying....
 *
 */
"use strict";

/**
 * ## Import immutable record
 */
import { Record } from "immutable";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
var InitialState = Record({
  isFetching: false,
  error: null,
  success: null,

  areas: [],
  areasDropDownItems: [],

  timeSlots: [],
  timeSlotsFiltered: [],
  lastTimeSlotPage: 0,
  totalTimeSlotResultRows: 0,
  totalTimeSlotRows: 0,
  entriesPerPageTimeSlotRows: 0,
  endReachedTimeSlotPage: false,

  paymentMethods: [],
  paymentMethodsFiltered: [],
  lastPaymentMethodPage: 0,
  totalPaymentMethodResultRows: 0,
  totalPaymentMethodRows: 0,
  entriesPerPagePaymentMethodRows: 0,
  endReachedPaymentMethodPage: false,

  deliveryVariants: [],
  deliveryVariantsFiltered: [],
  lastDeliveryVariantPage: 0,
  totalDeliveryVariantResultRows: 0,
  totalDeliveryVariantRows: 0,
  entriesPerPageDeliveryVariantRows: 0,
  endReachedDeliveryVariantPage: false,

  priceTypes: [],
  priceTypesFiltered: [],
  lastPriceTypePage: 0,
  totalPriceTypeResultRows: 0,
  totalPriceTypeRows: 0,
  entriesPerPagePriceTypeRows: 0,
  endReachedPriceTypePage: false,

  order: null,

  orders: [],
  ordersFiltered: [],
  lastOrderPage: 0,
  totalOrderResultRows: 0,
  totalOrderRows: 0,
  entriesPerPageOrderRows: 0,
  endReachedOrderPage: false,

  ordersQty: 0,

  orderStatus: [
    {id: "new", name_ru: "Новый"},
    {id: "process", name_ru: "В процессе"},
    {id: "completed", name_ru: "Собран"},
    {id: "delivery", name_ru: "В доставке"},
    {id: "finished", name_ru: "Завершен"},
  ],

  warehouses: [],

  allGroups: [],
  allGroupsFiltered: [],

  allUsers: [],
  allUsersFiltered: [],

  allPriceTypes: [],
  allPriceTypesFiltered: [],

  goods: [],
  goodsFiltered: [],
  lastGoodPage: 0,
  totalGoodResultRows: 0,
  totalGoodRows: 0,
  entriesPerPageGoodRows: 0,
  endReachedGoodPage: false,

  selectedGroup: null,
  selectedStatus: null,

  groups: [],
  groupsFiltered: [],
  lastGroupPage: 0,
  totalGroupResultRows: 0,
  totalGroupRows: 0,
  entriesPerPageGroupRows: 0,
  endReachedGroupPage: false,

  exclusiveGoodsFiltered: [],
  bestsellingGoodsFiltered: [],
  groceriesGoodsFiltered: [],

  goodsDetails: null,

  myCart: [],
  lastCartPage: 0,
  endReachedCartPage: false,

  myFavorites: [],
  lastFavoritesPage: 0,
  endReachedFavoritesPage: false,

  myOrders: [],
  lastOrdersPage: 0,
  endReachedOrdersPage: false,

  deletedUserData: null,

  mySearchGoods: [],
  mySearchGoodsFiltered: [],
  lastSearchGoodsPage: 0,
  endReachedSearchGoodsPage: false,

  messages: [],
  messagesFiltered: [],
  lastMessagesPage: 0,
  totalMessagesResultRows: 0,
  totalMessagesRows: 0,
  entriesPerPageMessagesRows: 0,
  endReachedMessagesPage: false,

  selectedMessageUser: null,

  chat: [],
  chatFiltered: [],
  lastChatPage: 0,
  totalChatResultRows: 0,
  totalChatRows: 0,
  entriesPerPageChatRows: 0,
  endReachedChatPage: false,

  selectedChatUser: null,

  // dashboard
  total_num_orders: 0, 
  total_num_goods: 0, 
  total_num_users: 0, 
  total_num_in_carts: 0,


  pricelistsByGood: [],

  imageListPaths: [],


  banners: [],
  bannersFiltered: [],
  lastBannersPage: 0,
  totalBannersResultRows: 0,
  totalBannersRows: 0,
  entriesPerPageBannersRows: 0,
  endReachedBannersPage: false,
  
});

export default InitialState;
