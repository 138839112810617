/**
 * # APIBackend.js
 *
 * Abstract Base class for API Backend support
 *
 */
"use strict";
/**
 * ## Async support
 *
 */

export default class APIBackend {
  check_or_create_user(data) {}

  check_client_token(data) {}

  get_areas(data) {}
}
