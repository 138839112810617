module.exports = {
  SESSION_TOKEN_KEY: "SESSION_TOKEN_KEY",
  backend: {
    firebaseRemote: true,
  },
  FIREBASE: {
    appId: "com.predictgroup.pgstore_admin", // match APP_ID in parse-server's index.js
  },
  PGSTORE_API: {
    app_name: "webadmin",
    get_users: "https://api1.predictgroup.com/api/v1/users/users", // GET
    create_new_user: "https://api1.predictgroup.com/api/v1/users/user", // POST
    get_user_info: "https://api1.predictgroup.com/api/v1/users/user", // GET
    delete_user_data: "https://api1.predictgroup.com/api/v1/users/user", // DELETE
    check_client_token:
      "https://api1.predictgroup.com/api/v1/pgstore/check_client_token", // GET
    update_user: "https://api1.predictgroup.com/api/v1/users/user_info", // POST
    get_all_users: "https://api1.predictgroup.com/api/v1/users/all_users", // GET

    get_areas: "https://api1.predictgroup.com/api/v1/pgstore/areas", // GET
    get_warehouses: "https://api1.predictgroup.com/api/v1/webadmin/warehouses", // GET
    get_groups: "https://api1.predictgroup.com/api/v1/webadmin/groups", // GET
    get_all_groups: "https://api1.predictgroup.com/api/v1/webadmin/all_groups", // GET
    get_group_by_id: "https://api1.predictgroup.com/api/v1/webadmin/groups/id/", // GET
    get_goods: "https://api1.predictgroup.com/api/v1/webadmin/goods", // GET
    get_good_by_id: "https://api1.predictgroup.com/api/v1/webadmin/goods/id/", // GET

    get_time_slots: "https://api1.predictgroup.com/api/v1/webadmin/time_slots", // GET
    set_time_slots_web: "https://api1.predictgroup.com/api/v1/webadmin/time_slots", // POST
    new_time_slots_web: "https://api1.predictgroup.com/api/v1/webadmin/new_time_slots", // POST

    get_payment_methods:
      "https://api1.predictgroup.com/api/v1/webadmin/payment_methods", // GET
    set_payment_methods_web: "https://api1.predictgroup.com/api/v1/webadmin/payment_methods", // POST
    new_payment_methods_web: "https://api1.predictgroup.com/api/v1/webadmin/new_payment_methods", // POST

    get_delivery_variants:
      "https://api1.predictgroup.com/api/v1/webadmin/delivery_variants", // GET
    set_delivery_variants_web: "https://api1.predictgroup.com/api/v1/webadmin/delivery_variants", // POST
    new_delivery_variants_web: "https://api1.predictgroup.com/api/v1/webadmin/new_delivery_variants", // POST

    get_price_types:
      "https://api1.predictgroup.com/api/v1/webadmin/price_types", // GET
    set_price_types_web: "https://api1.predictgroup.com/api/v1/webadmin/price_types", // POST
    new_price_types_web: "https://api1.predictgroup.com/api/v1/webadmin/new_price_types", // POST
    get_all_price_types: "https://api1.predictgroup.com/api/v1/webadmin/all_price_types", // GET

    get_pricelists_by_good_id: "https://api1.predictgroup.com/api/v1/webadmin/pricelists/good_id/", // GET

    get_cart: "https://api1.predictgroup.com/api/v1/pgstore/cart", // GET
    add_to_cart: "https://api1.predictgroup.com/api/v1/pgstore/cart", // POST

    get_favorites: "https://api1.predictgroup.com/api/v1/pgstore/favorites", // GET
    add_to_favorites: "https://api1.predictgroup.com/api/v1/pgstore/favorites", // POST

    add_to_orders: "https://api1.predictgroup.com/api/v1/pgstore/orders", // POST
    get_orders: "https://api1.predictgroup.com/api/v1/webadmin/orders", // GET
    get_orders_qty: "https://api1.predictgroup.com/api/v1/webadmin/orders_qty", // GET
    set_order_status: "https://api1.predictgroup.com/api/v1/webadmin/order_status", // POST

    set_message_read: "https://api1.predictgroup.com/api/v1/pgstore/messages", // POST
    get_messages: "https://api1.predictgroup.com/api/v1/webadmin/messages", // GET
    add_new_message: "https://api1.predictgroup.com/api/v1/webadmin/new_message", // POST
    set_message: "https://api1.predictgroup.com/api/v1/webadmin/messages", // POST

    set_chat_read: "https://api1.predictgroup.com/api/v1/webadmin/set_chat_read", // POST
    get_chat: "https://api1.predictgroup.com/api/v1/webadmin/chat", // GET
    add_to_chat: "https://api1.predictgroup.com/api/v1/webadmin/new_chat", // POST
    set_chat: "https://api1.predictgroup.com/api/v1/webadmin/chat", // POST

    get_goods_web: "https://api1.predictgroup.com/api/v1/webadmin/goods", // GET
    set_goods_web: "https://api1.predictgroup.com/api/v1/webadmin/goods", // POST
    new_goods_web: "https://api1.predictgroup.com/api/v1/webadmin/new_goods", // POST

    get_groups_web: "https://api1.predictgroup.com/api/v1/webadmin/groups", // GET
    set_groups_web: "https://api1.predictgroup.com/api/v1/webadmin/groups", // POST
    new_groups_web: "https://api1.predictgroup.com/api/v1/webadmin/new_groups", // POST

    get_dashboard_qty: "https://api1.predictgroup.com/api/v1/webadmin/dashboard_qty", // GET

    set_upload_image: "https://api1.predictgroup.com/api/v1/webadmin/upload_image", // POST

    get_banners: "https://api1.predictgroup.com/api/v1/webadmin/banners", // GET
    add_new_banner: "https://api1.predictgroup.com/api/v1/webadmin/new_banner", // POST
    set_banner: "https://api1.predictgroup.com/api/v1/webadmin/banners", // POST
  },
  PGSTORE_CONSTANTS: {
    exclusive_group_id: "62dd718758dffd17c348e471",
    bestselling_group_id: "62dd7c3a58dffd17c348e47f",
    groceries_group_id: "62dd7c5e58dffd17c348e480",
  },
};
