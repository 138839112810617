/**
 * # FirebaseApi.js
 *
 * This class interfaces with parse-server using the rest api
 *
 *
 */
"use strict";

import _ from "underscore";
import Backend from "./Backend";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  updateProfile,
  getIdToken,
  getProfile,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
  sendEmailVerification,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { firebaseConfig } from '../config/firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const providerGoogle = new GoogleAuthProvider();

export class FirebaseApi extends Backend {
  /**
   * ## FirebaseApi.js client
   *
   *
   * @throws tokenMissing if token is undefined
   */
  initialize() { }

  /**
   * ### signup
   *
   * @param data object
   *
   * {username: "barton", email: "foo@gmail.com", password: "Passw0rd!"}
   *
   * @return
   * if ok, res.json={createdAt: "2015-12-30T15:17:05.379Z",
   *   objectId: "5TgExo2wBA",
   *   sessionToken: "r:dEgdUkcs2ydMV9Y9mt8HcBrDM"}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async signup(data) {
    return await createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((loggedInUser) => {

        return updateProfile(auth.currentUser, {
          displayName: data.username, photoURL: null
        })
      })
      .then(() => {
        // Profile updated!
        return Promise.resolve(auth.currentUser);
      })
      .then((UserCredential) => {
        return Promise.all([auth.currentUser.getIdToken(), UserCredential]);
      })
      .catch((error) => {
        console.log("Register fail with error!");

        return Promise.reject(error);
      });
  }

  /**
 * ### signupSocial
 *
 * @param data object
 *
 * {social: "google", "facebook"}
 *
 * @return
 * if ok, res.json={createdAt: "2015-12-30T15:17:05.379Z",
 *   objectId: "5TgExo2wBA",
 *   sessionToken: "r:dEgdUkcs2ydMV9Y9mt8HcBrDM"}
 *
 * if error, {code: xxx, error: 'message'}
 */
  async signupSocial(data) {
    //console.log('signupSocial: ', data);
    switch (data.social) {
      case 'google':
        return await this.signupGoogle()
          .then(loggedInUser => {
            //console.log('signupSocial google: ', loggedInUser);
            return Promise.all([loggedInUser.accessToken, auth.currentUser]);
          })

          .catch(error => {
            console.log('Register Google fail with error! ', error);

            return Promise.reject(error);
          });
        break;

      default:
        break;
    }
  }
  async signupGoogle() {
    return await signInWithPopup(auth, providerGoogle)

      .then(userInfo => {
        //console.log('userInfo ', userInfo);
        //const credential = GoogleAuthProvider.credentialFromResult(userInfo);
        //const token = credential.accessToken;
        // The signed-in user info.
        const user = userInfo.user;
        //console.log('signupSocial google 1: ', user);
        return user;
      })

      .catch(error => {
        console.log('There was an error ', error);
        return Promise.reject(error);
      });
  }

  /**
   * ### login
   * encode the data and and call _fetch
   *
   * @param data
   *
   *  {username: "barton", password: "Passw0rd!"}
   *
   * @returns
   *
   * createdAt: "2015-12-30T15:29:36.611Z"
   * updatedAt: "2015-12-30T16:08:50.419Z"
   * objectId: "Z4yvP19OeL"
   * email: "barton@foo.com"
   * sessionToken: "r:Kt9wXIBWD0dNijNIq2u5rRllW"
   * username: "barton"
   *
   */
  async login(data) {
    return await signInWithEmailAndPassword(auth, data.email, data.password)
      .then((UserCredential) => {
        console.log("UserCredential: ", UserCredential);
        return Promise.all([auth.currentUser.getIdToken(), auth.currentUser]);
      })
      .catch((error) => {
        console.warn("Login fail!! ", error);

        return Promise.reject(error);
      });
  }

  /**
   * ### logout
   * prepare the request and call _fetch
   */
  async logout() {
    var user = auth.currentUser;
    if (user) {
      console.log("User logout 1: ", user.providerData[0].providerId);
      // if (user.providerData[0].providerId === 'facebook.com') {
      //     //manager.deauthorize('facebook');
      //     LoginManager.logOut();
      // }
      // if (user.providerData[0].providerId === 'google.com') {
      //     GoogleSignin.revokeAccess();
      //     GoogleSignin.signOut();
      // }
    }

    return await signOut(auth)
      .then(() => {
        return {};
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * ### resetPassword
   * the data is already in a JSON format, so call _fetch
   *
   * @param data
   * {email: "barton@foo.com"}
   *
   * @returns empty object
   *
   * if error:  {code: xxx, error: 'message'}
   */
  async resetPassword(data) {
    return await sendPasswordResetEmail(auth, data.email)
      .then(() => {
        return {};
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * ### verifyEmail
   * the data is already in a JSON format, so call _fetch
   *
   * @param data
   * {email: "barton@foo.com"}
   *
   * @returns empty object
   *
   * if error:  {code: xxx, error: 'message'}
   */
  async verifyEmail(data) {
    const user = auth.currentUser;
    user.reload(); // refresh user data
    if (user) {
      if (!user.emailVerified) {
        if (user.providerData[0].providerId === "password") {
          return await sendEmailVerification(auth.currentUser)
            .then(() => {
              console.log("verifyEmail good");
              signOut(auth);

              return Promise.resolve("Проверьте подтверждение электронной почты, пожалуйста!");
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        } else {
          return Promise.all([auth.currentUser.getIdToken(), auth.currentUser]);
        }
      } else {
        return Promise.all([auth.currentUser.getIdToken(), auth.currentUser]);
      }
    } else {
      return  Promise.resolve("Проверьте подтверждение электронной почты, пожалуйста!");
    }
  }
  async verifyEmail_withreject(data) {
    const user = auth.currentUser;
    user.reload(); // refresh user data
    if (user) {
      if (!user.emailVerified) {
        if (user.providerData[0].providerId === "password") {
          return await sendEmailVerification(auth.currentUser)
            .then(() => {
              console.log("verifyEmail good");
              signOut(auth);

              return Promise.reject(new Error("Проверьте подтверждение электронной почты, пожалуйста!"));
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        } else {
          return Promise.all([auth.currentUser.getIdToken(), auth.currentUser]);
        }
      } else {
        return Promise.all([auth.currentUser.getIdToken(), auth.currentUser]);
      }
    } else {
      return Promise.reject(new Error("Проверьте подтверждение электронной почты, пожалуйста!"));
    }
  }

  /**
   * ### getProfile
   * Using the sessionToken, we'll get everything about
   * the current user.
   *
   * @returns
   *
   * if good:
   * {createdAt: "2015-12-30T15:29:36.611Z"
   *  email: "barton@acclivyx.com"
   *  objectId: "Z4yvP19OeL"
   *  sessionToken: "r:uFeYONgIsZMPyxOWVJ6VqJGqv"
   *  updatedAt: "2015-12-30T15:29:36.611Z"
   *  username: "barton"}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async getProfile(data) {
    var user = auth.currentUser;

    if (user) {
      return {
        username: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
      };
    } else {
      return Promise.reject();
    }
  }

  /**
   * ### updateProfile
   * for this user, update their record
   * the data is already in JSON format
   *
   * @param userId  _id of Parse.com
   * @param data object:
   * {username: "barton", email: "barton@foo.com"}
   */
  async updateProfile(userId, data) {
    const update = {
      displayName: data.username,
      photoURL: null,
    };

    return await auth.currentUser
      .updateProfile(update)
      .then(() => {
        return {};
      })
      .then(() => {
        const user = auth.currentUser;
        if (user.email !== data.email) {
          return user.verifyBeforeUpdateEmail(data.email);
        } else {
          return {};
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * ### getUserUid
   *
   *
   * @returns user uid
   *
   * if error:  {code: xxx, error: 'message'}
   */
  async getUserUid() {
    const user = auth.currentUser;

    return await this.getFirebaseToken()
      .then((token) => {
        //console.log('getFirebaseToken 111: ', user.uid, token);
        return Promise.resolve({
          uid: user.uid,
          token: token,
        });
      })
      .catch((error) => {
        console.log("getFirebaseToken error: ", user.uid);
        return Promise.reject(error);
      });
  }

  /**
   * ### getUser
   *
   *
   * @returns user
   *
   * if error:  {code: xxx, error: 'message'}
   */
  async getUser() {
    return new Promise((resolve) =>
      onAuthStateChanged(auth, (user) => resolve(user))
    );
  }

  /**
   * ### getFirebaseAuthApp
   *
   *
   * @returns user
   *
   * if error:  {code: xxx, error: 'message'}
   */
  getFirebaseAuthApp() {
    return auth;
  }

  /**
   * ### getCurrentUser
   *
   *
   * @returns user
   *
   * if error:  {code: xxx, error: 'message'}
   */
  getCurrentUser() {
    auth.currentUser.reload();
    return auth.currentUser;
  }

  /**
   * ### getFirebaseToken
   *
   *
   * @returns token
   *
   * if error:  {code: xxx, error: 'message'}
   */
  async getFirebaseToken() {
    if (!auth.currentUser) {
      console.log("getFirebaseToken: not logged in");
      return Promise.reject("not logged in");
    }
    return await auth.currentUser
      .getIdToken()
      .then((token) => {
        //console.log('getFirebaseToken token: ', token);
        return Promise.resolve(token);
      })
      .catch((error) => {
        console.log("getFirebaseToken error: ", error);

        return error;
      });
  }

  /**
   * ### delete user
   */
  async delete() {
    var user = auth.currentUser;

    return await user
      .delete()
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  // -------------=====-------------
}

// The singleton variable
export let firebaseApi = new FirebaseApi();
