/**
 * # storedataActions.js
 *
 * What platform are we running on, ie ```ios``` or ```android```
 *
 * What version is the app?
 *
 */
'use strict'

/**
 * ## Imports
 *
 * The actions supported
 */
const {
  GET_AREAS_REQUEST,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,

  GET_WAREHOUSES_REQUEST,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,

  GET_TIME_SLOTS_REQUEST,
  GET_TIME_SLOTS_SUCCESS,
  GET_TIME_SLOTS_FAILURE,

  SET_TIME_SLOTS_REQUEST,
  SET_TIME_SLOTS_SUCCESS,
  SET_TIME_SLOTS_FAILURE,

  ADD_TIME_SLOTS_REQUEST,
  ADD_TIME_SLOTS_SUCCESS,
  ADD_TIME_SLOTS_FAILURE,

  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,

  SET_PAYMENT_METHODS_REQUEST,
  SET_PAYMENT_METHODS_SUCCESS,
  SET_PAYMENT_METHODS_FAILURE,

  ADD_PAYMENT_METHODS_REQUEST,
  ADD_PAYMENT_METHODS_SUCCESS,
  ADD_PAYMENT_METHODS_FAILURE,

  GET_DELIVERY_VARIANTS_REQUEST,
  GET_DELIVERY_VARIANTS_SUCCESS,
  GET_DELIVERY_VARIANTS_FAILURE,

  SET_DELIVERY_VARIANTS_REQUEST,
  SET_DELIVERY_VARIANTS_SUCCESS,
  SET_DELIVERY_VARIANTS_FAILURE,

  ADD_DELIVERY_VARIANTS_REQUEST,
  ADD_DELIVERY_VARIANTS_SUCCESS,
  ADD_DELIVERY_VARIANTS_FAILURE,

  GET_PRICE_TYPES_REQUEST,
  GET_PRICE_TYPES_SUCCESS,
  GET_PRICE_TYPES_FAILURE,

  SET_PRICE_TYPES_REQUEST,
  SET_PRICE_TYPES_SUCCESS,
  SET_PRICE_TYPES_FAILURE,

  ADD_PRICE_TYPES_REQUEST,
  ADD_PRICE_TYPES_SUCCESS,
  ADD_PRICE_TYPES_FAILURE,

  GET_ALL_GROUPS_REQUEST,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAILURE,

  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,

  GET_ALL_PRICE_TYPES_REQUEST,
  GET_ALL_PRICE_TYPES_SUCCESS,
  GET_ALL_PRICE_TYPES_FAILURE,

  GET_GOODS_REQUEST,
  GET_GOODS_SUCCESS,
  GET_GOODS_FAILURE,

  SET_GOODS_REQUEST,
  SET_GOODS_SUCCESS,
  SET_GOODS_FAILURE,

  ADD_GOODS_REQUEST,
  ADD_GOODS_SUCCESS,
  ADD_GOODS_FAILURE,

  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,

  SET_GROUPS_REQUEST,
  SET_GROUPS_SUCCESS,
  SET_GROUPS_FAILURE,

  ADD_GROUPS_REQUEST,
  ADD_GROUPS_SUCCESS,
  ADD_GROUPS_FAILURE,

  GET_GROUPS_DETAILS_REQUEST,
  GET_GROUPS_DETAILS_SUCCESS,
  GET_GROUPS_DETAILS_FAILURE,

  GET_GOODS_DETAILS_REQUEST,
  GET_GOODS_DETAILS_SUCCESS,
  GET_GOODS_DETAILS_FAILURE,

  GET_MY_CART_REQUEST,
  GET_MY_CART_SUCCESS,
  GET_MY_CART_FAILURE,

  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,

  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAILURE,

  ADD_TO_FAVORITES_REQUEST,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILURE,

  ADD_TO_ORDERS_REQUEST,
  ADD_TO_ORDERS_SUCCESS,
  ADD_TO_ORDERS_FAILURE,

  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,

  GET_ORDERS_QTY_REQUEST,
  GET_ORDERS_QTY_SUCCESS,
  GET_ORDERS_QTY_FAILURE,

  SET_ORDER_STATUS_REQUEST,
  SET_ORDER_STATUS_SUCCESS,
  SET_ORDER_STATUS_FAILURE,

  DELETE_USER_DATA_REQUEST,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_FAILURE,

  SEARCH_GOODS_REQUEST,
  SEARCH_GOODS_SUCCESS,
  SEARCH_GOODS_FAILURE,

  LOGOUT_CLEAR_REQUEST,
  LOGOUT_CLEAR_SUCCESS,
  LOGOUT_CLEAR_FAILURE,

  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,

  SET_MESSAGE_REQUEST,
  SET_MESSAGE_SUCCESS,
  SET_MESSAGE_FAILURE,

  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE,

  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAILURE,

  SET_CHAT_READ_REQUEST,
  SET_CHAT_READ_SUCCESS,
  SET_CHAT_READ_FAILURE,

  ADD_TO_CHAT_REQUEST,
  ADD_TO_CHAT_SUCCESS,
  ADD_TO_CHAT_FAILURE,

  SET_CHAT_REQUEST,
  SET_CHAT_SUCCESS,
  SET_CHAT_FAILURE,

  GET_DASHBOARD_QTY_REQUEST,
  GET_DASHBOARD_QTY_SUCCESS,
  GET_DASHBOARD_QTY_FAILURE,

  GET_PRICELISTS_BY_GOOD_ID_REQUEST,
  GET_PRICELISTS_BY_GOOD_ID_SUCCESS,
  GET_PRICELISTS_BY_GOOD_ID_FAILURE,

  SET_UPLOAD_IMAGE_REQUEST,
  SET_UPLOAD_IMAGE_SUCCESS,
  SET_UPLOAD_IMAGE_FAILURE,

  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILURE,

  SET_BANNER_REQUEST,
  SET_BANNER_SUCCESS,
  SET_BANNER_FAILURE,

  ADD_BANNER_REQUEST,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAILURE,

} = require('../../config/constants').default

/**
 * Project requirements
 */
const BackendFactory = require('../../lib/BackendFactory').default
const APIBackendFactory = require('../../lib/APIBackendFactory').default

const _ = require('underscore')

/**
 * ## getAreas actions
 */
export function getAreasRequest() {
  return {
    type: GET_AREAS_REQUEST,
  }
}
export function getAreasSuccess(json) {
  return {
    type: GET_AREAS_SUCCESS,
    payload: json,
  }
}
export function getAreasFailure(error) {
  return {
    type: GET_AREAS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getAreas
 *
 */
export function getAreas() {
  //console.log("getAreas 1");
  return (dispatch) => {
    dispatch(getAreasRequest())
    // return BackendFactory().getFirebaseToken()
    //     .then(token => {
    //         //console.log("getAreas token: ", token);
    //         return APIBackendFactory({ sessionToken: token }).get_areas({ token });
    //     })
    return APIBackendFactory()
      .get_areas()
      .then((json) => {
        //console.log("getAreas: ", json);

        const status = json.status
        const data = json.data.areas

        //console.log("getAreas resp: ", status, data);

        dispatch(getAreasSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getAreasFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getWarehouses actions
 */
export function getWarehousesRequest() {
  return {
    type: GET_WAREHOUSES_REQUEST,
  }
}
export function getWarehousesSuccess(json) {
  return {
    type: GET_WAREHOUSES_SUCCESS,
    payload: json,
  }
}
export function getWarehousesFailure(error) {
  return {
    type: GET_WAREHOUSES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getWarehouses
 *
 */
export function getWarehouses() {
  //console.log("getWarehouses 1");
  return (dispatch) => {
    dispatch(getWarehousesRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getWarehouses token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_warehouses({ token });
      })
      .then((json) => {
        //console.log("getWarehouses: ", json);

        const status = json.status
        const data = json.data.warehouses

        //console.log("getWarehouses resp: ", status, data);

        dispatch(getWarehousesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getWarehousesFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getTimeSlots actions
 */
export function getTimeSlotsRequest() {
  return {
    type: GET_TIME_SLOTS_REQUEST,
  }
}
export function getTimeSlotsSuccess(json, page, dataobj) {
  return {
    type: GET_TIME_SLOTS_SUCCESS,
    payload: { json, page, dataobj },
  }
}
export function getTimeSlotsFailure(error) {
  return {
    type: GET_TIME_SLOTS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getTimeSlots
 *
 */
export function getTimeSlots(page = 0) {
  //console.log("getTimeSlots 1");
  var params = {}
  if (page) {
    params = { ...params, page: page }
  }
  return (dispatch) => {
    dispatch(getTimeSlotsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getTimeSlots token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_time_slots({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getTimeSlots: ", json);

        const status = json.status
        const data = json.data.time_slots
        const dataobj = json.data

        //console.log("getTimeSlots resp: ", status, data);

        dispatch(getTimeSlotsSuccess(data, page, dataobj))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getTimeSlotsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## updateTimeSlots actions
 */
export function updateTimeSlotsRequest() {
  return {
    type: SET_TIME_SLOTS_REQUEST,
  }
}
export function updateTimeSlotsSuccess(json) {
  return {
    type: SET_TIME_SLOTS_SUCCESS,
    payload: json,
  }
}
export function updateTimeSlotsFailure(error) {
  return {
    type: SET_TIME_SLOTS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## updateTimeSlots
 *
 */
export function updateTimeSlots(
  time_slot_id,
  TimeStart,
  TimeEnd,
  Desc,
  Active,
) {
  //console.log("updateTimeSlots: ", delivery_variant_id);
  return (dispatch) => {
    dispatch(updateTimeSlotsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("updateTimeSlots token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_time_slots({
          time_slot_id,
          TimeStart,
          TimeEnd,
          Desc,
          Active,
        })
      })

      .then((json) => {
        //console.log("updateTimeSlots: ", json);

        const status = json.status
        const data = json.data.time_slot

        //console.log("updateTimeSlots resp: ", status, data);

        dispatch(updateTimeSlotsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(updateTimeSlotsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addTimeSlots actions
 */
export function addTimeSlotsRequest() {
  return {
    type: ADD_TIME_SLOTS_REQUEST,
  }
}
export function addTimeSlotsSuccess(json) {
  return {
    type: ADD_TIME_SLOTS_SUCCESS,
    payload: json,
  }
}
export function addTimeSlotsFailure(error) {
  return {
    type: ADD_TIME_SLOTS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addTimeSlots
 *
 */
export function addTimeSlots(
  TimeStart,
  TimeEnd,
  Desc,
  Active,
) {
  //console.log("addTimeSlots ");
  return (dispatch) => {
    dispatch(addPaymentMethodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addTimeSlots token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_time_slots({
          TimeStart,
          TimeEnd,
          Desc,
          Active,
        })
      })

      .then((json) => {
        //console.log("addTimeSlots: ", json);

        const status = json.status
        const data = json.data.time_slot

        //console.log("addTimeSlots resp: ", status, data);

        dispatch(addTimeSlotsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addTimeSlotsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getPaymentMethods actions
 */
export function getPaymentMethodsRequest() {
  return {
    type: GET_PAYMENT_METHODS_REQUEST,
  }
}
export function getPaymentMethodsSuccess(json, page, dataobj) {
  return {
    type: GET_PAYMENT_METHODS_SUCCESS,
    payload: { json, page, dataobj },
  }
}
export function getPaymentMethodsFailure(error) {
  return {
    type: GET_PAYMENT_METHODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getPaymentMethods
 *
 */
export function getPaymentMethods(page = 0) {
  //console.log("getPaymentMethods 1");
  var params = {}
  if (page) {
    params = { ...params, page: page }
  }
  return (dispatch) => {
    dispatch(getPaymentMethodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getPaymentMethods token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_payment_methods({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getPaymentMethods: ", json);

        const status = json.status;
        const data = json.data.payment_methods;
        const dataobj = json.data;

        //console.log("getPaymentMethods resp: ", status, data);

        dispatch(getPaymentMethodsSuccess(data, page, dataobj))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getPaymentMethodsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## updatePaymentMethods actions
 */
export function updatePaymentMethodsRequest() {
  return {
    type: SET_PAYMENT_METHODS_REQUEST,
  }
}
export function updatePaymentMethodsSuccess(json) {
  return {
    type: SET_PAYMENT_METHODS_SUCCESS,
    payload: json,
  }
}
export function updatePaymentMethodsFailure(error) {
  return {
    type: SET_PAYMENT_METHODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## updatePaymentMethods
 *
 */
export function updatePaymentMethods(
  payment_method_id,
  desc,
  Active,
) {
  //console.log("updatePaymentMethods: ", delivery_variant_id);
  return (dispatch) => {
    dispatch(updatePaymentMethodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("updatePaymentMethods token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_payment_methods({
          payment_method_id,
          desc,
          Active,
        })
      })

      .then((json) => {
        //console.log("updatePaymentMethods: ", json);

        const status = json.status
        const data = json.data.payment_method

        //console.log("updatePaymentMethods resp: ", status, data);

        dispatch(updatePaymentMethodsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(updatePaymentMethodsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addPaymentMethods actions
 */
export function addPaymentMethodsRequest() {
  return {
    type: ADD_PAYMENT_METHODS_REQUEST,
  }
}
export function addPaymentMethodsSuccess(json) {
  return {
    type: ADD_PAYMENT_METHODS_SUCCESS,
    payload: json,
  }
}
export function addPaymentMethodsFailure(error) {
  return {
    type: ADD_PAYMENT_METHODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addPaymentMethods
 *
 */
export function addPaymentMethods(
  desc,
  Active,
) {
  //console.log("addPaymentMethods ");
  return (dispatch) => {
    dispatch(addPaymentMethodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addPaymentMethods token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_payment_methods({
          desc,
          Active,
        })
      })

      .then((json) => {
        //console.log("addPaymentMethods: ", json);

        const status = json.status
        const data = json.data.payment_method

        //console.log("addPaymentMethods resp: ", status, data);

        dispatch(addPaymentMethodsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addPaymentMethodsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getDeliveryVariants actions
 */
export function getDeliveryVariantsRequest() {
  return {
    type: GET_DELIVERY_VARIANTS_REQUEST,
  }
}
export function getDeliveryVariantsSuccess(json, page, dataobj) {
  return {
    type: GET_DELIVERY_VARIANTS_SUCCESS,
    payload: { json, page, dataobj },
  }
}
export function getDeliveryVariantsFailure(error) {
  return {
    type: GET_DELIVERY_VARIANTS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getDeliveryVariants
 *
 */
export function getDeliveryVariants(page = 0) {
  //console.log("getDeliveryVariants 1");
  var params = {}
  if (page) {
    params = { ...params, page: page }
  }
  return (dispatch) => {
    dispatch(getDeliveryVariantsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getDeliveryVariants token: ", token);
        return APIBackendFactory({ sessionToken: token })
          .get_delivery_variants({
            token: token,
            params: params,
          })
      })

      .then((json) => {
        //console.log("getDeliveryVariants: ", json);

        const status = json.status;
        const data = json.data.delivery_variants;
        const dataobj = json.data;

        //console.log("getDeliveryVariants resp: ", status, data);

        dispatch(getDeliveryVariantsSuccess(data, page, dataobj))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getDeliveryVariantsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## updateDeliveryVariants actions
 */
export function updateDeliveryVariantsRequest() {
  return {
    type: SET_DELIVERY_VARIANTS_REQUEST,
  }
}
export function updateDeliveryVariantsSuccess(json) {
  return {
    type: SET_DELIVERY_VARIANTS_SUCCESS,
    payload: json,
  }
}
export function updateDeliveryVariantsFailure(error) {
  return {
    type: SET_DELIVERY_VARIANTS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## updateDeliveryVariants
 *
 */
export function updateDeliveryVariants(
  delivery_variant_id,
  desc,
  price,
  Active,
) {
  //console.log("updateDeliveryVariants: ", delivery_variant_id);
  return (dispatch) => {
    dispatch(updateDeliveryVariantsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("updateDeliveryVariants token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_delivery_variants({
          delivery_variant_id,
          desc,
          price: price,
          Active,
        })
      })

      .then((json) => {
        //console.log("updateDeliveryVariants: ", json);

        const status = json.status
        const data = json.data.delivery_variant

        //console.log("updateDeliveryVariants resp: ", status, data);

        dispatch(updateDeliveryVariantsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(updateDeliveryVariantsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addDeliveryVariants actions
 */
export function addDeliveryVariantsRequest() {
  return {
    type: ADD_DELIVERY_VARIANTS_REQUEST,
  }
}
export function addDeliveryVariantsSuccess(json) {
  return {
    type: ADD_DELIVERY_VARIANTS_SUCCESS,
    payload: json,
  }
}
export function addDeliveryVariantsFailure(error) {
  return {
    type: ADD_DELIVERY_VARIANTS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addDeliveryVariants
 *
 */
export function addDeliveryVariants(
  desc,
  price,
  Active,
) {
  //console.log("addDeliveryVariants ");
  return (dispatch) => {
    dispatch(addDeliveryVariantsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addDeliveryVariants token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_delivery_variants({
          desc,
          price: price,
          Active,
        })
      })

      .then((json) => {
        //console.log("addDeliveryVariants: ", json);

        const status = json.status
        const data = json.data.delivery_variant

        //console.log("addDeliveryVariants resp: ", status, data);

        dispatch(addDeliveryVariantsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addDeliveryVariantsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getPriceTypes actions
 */
export function getPriceTypesRequest() {
  return {
    type: GET_PRICE_TYPES_REQUEST,
  }
}
export function getPriceTypesSuccess(json, page, dataobj) {
  return {
    type: GET_PRICE_TYPES_SUCCESS,
    payload: { json, page, dataobj },
  }
}
export function getPriceTypesFailure(error) {
  return {
    type: GET_PRICE_TYPES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getPriceTypes
 *
 */
export function getPriceTypes(page = 0) {
  //console.log("getPriceTypes 1");
  var params = {}
  if (page) {
    params = { ...params, page: page }
  }
  return (dispatch) => {
    dispatch(getPriceTypesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getPriceTypes token: ", token);
        return APIBackendFactory({ sessionToken: token })
          .get_price_types({
            token: token,
            params: params,
          })
      })

      .then((json) => {
        //console.log("getPriceTypes: ", json);

        const status = json.status;
        const data = json.data.price_types;
        const dataobj = json.data;

        //console.log("getPriceTypes resp: ", status, data);

        dispatch(getPriceTypesSuccess(data, page, dataobj))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getPriceTypesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## updatePriceTypes actions
 */
export function updatePriceTypesRequest() {
  return {
    type: SET_PRICE_TYPES_REQUEST,
  }
}
export function updatePriceTypesSuccess(json) {
  return {
    type: SET_PRICE_TYPES_SUCCESS,
    payload: json,
  }
}
export function updatePriceTypesFailure(error) {
  return {
    type: SET_PRICE_TYPES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## updatePriceTypes
 *
 */
export function updatePriceTypes(
  price_type_id,
  desc,
  percent_info,
  Active,
) {
  //console.log("updatePriceTypes: ", delivery_variant_id);
  return (dispatch) => {
    dispatch(updatePriceTypesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("updatePriceTypes token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_price_types({
          price_type_id,
          desc,
          percent_info: percent_info,
          Active,
        })
      })

      .then((json) => {
        //console.log("updatePriceTypes: ", json);

        const status = json.status
        const data = json.data.price_type

        //console.log("updatePriceTypes resp: ", status, data);

        dispatch(updatePriceTypesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(updatePriceTypesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addPriceTypes actions
 */
export function addPriceTypesRequest() {
  return {
    type: ADD_PRICE_TYPES_REQUEST,
  }
}
export function addPriceTypesSuccess(json) {
  return {
    type: ADD_PRICE_TYPES_SUCCESS,
    payload: json,
  }
}
export function addPriceTypesFailure(error) {
  return {
    type: ADD_PRICE_TYPES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addPriceTypes
 *
 */
export function addPriceTypes(
  desc,
  percent_info,
  Active,
) {
  //console.log("addPriceTypes ");
  return (dispatch) => {
    dispatch(addPriceTypesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addPriceTypes token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_price_types({
          desc,
          percent_info: percent_info,
          Active,
        })
      })

      .then((json) => {
        //console.log("addPriceTypes: ", json);

        const status = json.status
        const data = json.data.price_type

        //console.log("addPriceTypes resp: ", status, data);

        dispatch(addPriceTypesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addPriceTypesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getAllGroups actions
 */
export function getAllGroupsRequest() {
  return {
    type: GET_ALL_GROUPS_REQUEST,
  }
}
export function getAllGroupsSuccess(json) {
  return {
    type: GET_ALL_GROUPS_SUCCESS,
    payload: json,
  }
}
export function getAllGroupsFailure(error) {
  return {
    type: GET_ALL_GROUPS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getAllGroups
 *
 */
export function getAllGroups(searchText = '') {
  //console.log("getAllGroups 1");
  var params = { text: searchText }
  return (dispatch) => {
    dispatch(getAllGroupsRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getAllGroups token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_all_groups(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getAllGroups: ", json);

        const status = json.status
        const data = json.data.groups

        console.log('getAllGroups resp: ', status, data)

        dispatch(getAllGroupsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getAllGroupsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getAllUsers actions
 */
export function getAllUsersRequest() {
  return {
    type: GET_ALL_USERS_REQUEST,
  }
}
export function getAllUsersSuccess(json) {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: json,
  }
}
export function getAllUsersFailure(error) {
  return {
    type: GET_ALL_USERS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getAllUsers
 *
 */
export function getAllUsers() {
  //console.log("getAllUsers 1");
  var params = {}
  return (dispatch) => {
    dispatch(getAllUsersRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getAllUsers token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_all_users(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getAllUsers: ", json);

        const status = json.status
        const data = json.data.users

        console.log('getAllUsers resp: ', status, data)

        dispatch(getAllUsersSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getAllUsersFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getAllPriceTypes actions
 */
export function getAllPriceTypesRequest() {
  return {
    type: GET_ALL_PRICE_TYPES_REQUEST,
  }
}
export function getAllPriceTypesSuccess(json) {
  return {
    type: GET_ALL_PRICE_TYPES_SUCCESS,
    payload: json,
  }
}
export function getAllPriceTypesFailure(error) {
  return {
    type: GET_ALL_PRICE_TYPES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getAllPriceTypes
 *
 */
export function getAllPriceTypes() {
  //console.log("getAllPriceTypes 1");
  var params = {}
  return (dispatch) => {
    dispatch(getAllPriceTypesRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getAllPriceTypes token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_all_price_types(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getAllPriceTypes: ", json);

        const status = json.status
        const data = json.data.price_types

        console.log('getAllPriceTypes resp: ', status, data)

        dispatch(getAllPriceTypesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getAllPriceTypesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getGroups actions
 */
export function getGroupsRequest() {
  return {
    type: GET_GROUPS_REQUEST,
  }
}
export function getGroupsSuccess(json, page, dataobj) {
  return {
    type: GET_GROUPS_SUCCESS,
    payload: { json: json, page: page, dataobj: dataobj },
  }
}
export function getGroupsFailure(error) {
  return {
    type: GET_GROUPS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getGroups
 *
 */
export function getGroups(page = 0) {
  //console.log("getGroups 1");
  var params = {}
  if (page) {
    params = { ...params, page: page }
  }
  return (dispatch) => {
    dispatch(getGroupsRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getGroups token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_groups(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getGroups: ", json);

        const status = json.status;
        const data = json.data.groups;
        const dataobj = json.data;

        //console.log('getGroups resp: ', status, data)

        dispatch(getGroupsSuccess(data, page, dataobj))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getGroupsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getGroupByID actions
 */
export function getGroupByIDRequest() {
  return {
    type: GET_GROUPS_DETAILS_REQUEST,
  }
}
export function getGroupByIDSuccess(json) {
  return {
    type: GET_GROUPS_DETAILS_SUCCESS,
    payload: json,
  }
}
export function getGroupByIDFailure(error) {
  return {
    type: GET_GROUPS_DETAILS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getGroupByID
 *
 */
export function getGroupByID(group_id) {
  //console.log("getGroupByID 1");
  var params = { id: group_id }
  return (dispatch) => {
    dispatch(getGroupByIDRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getGroupByID token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_group_by_id(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getGroupByID: ", json);

        const status = json.status
        const data = json.data.good

        //console.log("getGroupByID resp: ", status, data);

        dispatch(getGroupByIDSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getGroupByIDFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## updateGroups actions
 */
export function updateGroupsRequest() {
  return {
    type: SET_GROUPS_REQUEST,
  }
}
export function updateGroupsSuccess(json) {
  return {
    type: SET_GROUPS_SUCCESS,
    payload: json,
  }
}
export function updateGroupsFailure(error) {
  return {
    type: SET_GROUPS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## updateGroups
 *
 */
export function updateGroups(
  group_id,
  name,
  color,
  isExplore,
  imgUrl,
  borderColor,
  Active,
) {
  //console.log("updateGroups: ", group_id);
  return (dispatch) => {
    dispatch(updateGroupsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("updateGroups token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_groups({
          group_id,
          name,
          color,
          isExplore,
          imgUrl,
          borderColor,
          Active,
        })
      })

      .then((json) => {
        //console.log("updateGroups: ", json);

        const status = json.status
        const data = json.data.group

        //console.log("updateGroups resp: ", status, data);

        dispatch(updateGroupsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(updateGroupsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## addGroups actions
 */
export function addGroupsRequest() {
  return {
    type: ADD_GROUPS_REQUEST,
  }
}
export function addGroupsSuccess(json) {
  return {
    type: ADD_GROUPS_SUCCESS,
    payload: json,
  }
}
export function addGroupsFailure(error) {
  return {
    type: ADD_GROUPS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addGroups
 *
 */
export function addGroups(
  name,
  color,
  isExplore,
  imgUrl,
  borderColor,
  Active,
) {
  //console.log("addGroups: ");
  return (dispatch) => {
    dispatch(addGroupsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addGroups token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_groups({
          name,
          color,
          isExplore,
          imgUrl,
          borderColor,
          Active,
        })
      })

      .then((json) => {
        //console.log("addGroups: ", json);

        const status = json.status
        const data = json.data.group

        //console.log("addGroups resp: ", status, data);

        dispatch(addGroupsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addGroupsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getGoods actions
 */
export function getGoodsRequest() {
  return {
    type: GET_GOODS_REQUEST,
  }
}
export function getGoodsSuccess(json, page, dataobj, selectedGroupItemId) {
  return {
    type: GET_GOODS_SUCCESS,
    payload: { json: json, page: page, dataobj: dataobj, selectedGroupItemId: selectedGroupItemId },
  }
}
export function getGoodsFailure(error) {
  return {
    type: GET_GOODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getGoods
 *
 */
export function getGoods(group_id, searchText = '', page = 0) {
  //console.log("getGoods 1");
  var params = {}
  if (group_id) {
    params = { ...params, group_id: group_id }
  }
  if (searchText !== '') {
    params = { ...params, text: searchText }
  }
  if (page) {
    params = { ...params, page: page }
  }
  return (dispatch) => {
    dispatch(getGoodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getGoods token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_goods({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getGoods: ", json);

        const status = json.status;
        const data = json.data.goods;
        const dataobj = json.data;

        //console.log('getGoods resp: ', status, dataobj)

        dispatch(getGoodsSuccess(data, page, dataobj, group_id))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getGoodsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getGoodByID actions
 */
export function getGoodByIDRequest() {
  return {
    type: GET_GOODS_DETAILS_REQUEST,
  }
}
export function getGoodByIDSuccess(json) {
  return {
    type: GET_GOODS_DETAILS_SUCCESS,
    payload: json,
  }
}
export function getGoodByIDFailure(error) {
  return {
    type: GET_GOODS_DETAILS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getGoodByID
 *
 */
export function getGoodByID(good_id) {
  //console.log("getGoodByID 1");
  var params = { id: good_id }
  return (dispatch) => {
    dispatch(getGoodByIDRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getGoodByID token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_good_by_id(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getGoodByID: ", json);

        const status = json.status
        const data = json.data.good

        //console.log("getGoodByID resp: ", status, data);

        dispatch(getGoodByIDSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getGoodByIDFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## updateGoods actions
 */
export function updateGoodsRequest() {
  return {
    type: SET_GOODS_REQUEST,
  }
}
export function updateGoodsSuccess(json) {
  return {
    type: SET_GOODS_SUCCESS,
    payload: json,
  }
}
export function updateGoodsFailure(error) {
  return {
    type: SET_GOODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## updateGoods
 *
 */
export function updateGoods(
  good_id,
  Article,
  Name,
  nutritions,
  desc,
  group_id,
  warehouse_id,
  Barcode,
  in_stock,
  multiplicity,
  nutritions_qty,
  country,
  imgUrl,
  price,
  price_info,
  isWeight,
  Active,
) {
  //console.log("updateGoods: ", good_id);
  return (dispatch) => {
    dispatch(updateGoodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("updateGoods token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_goods({
          good_id,
          Article,
          Name,
          nutritions,
          desc,
          group_id,
          warehouse_id,
          Barcode,
          in_stock: in_stock,
          multiplicity,
          nutritions_qty,
          country,
          imgUrl,
          price: price,
          price_info: price_info,
          isWeight,
          Active,
        })
      })

      .then((json) => {
        //console.log("updateGoods: ", json);

        const status = json.status
        const data = json.data.good

        //console.log("updateGoods resp: ", status, data);

        dispatch(updateGoodsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(updateGoodsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addGoods actions
 */
export function addGoodsRequest() {
  return {
    type: ADD_GOODS_REQUEST,
  }
}
export function addGoodsSuccess(json) {
  return {
    type: ADD_GOODS_SUCCESS,
    payload: json,
  }
}
export function addGoodsFailure(error) {
  return {
    type: ADD_GOODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addGoods
 *
 */
export function addGoods(
  Article,
  Name,
  nutritions,
  desc,
  group_id,
  warehouse_id,
  Barcode,
  in_stock,
  multiplicity,
  nutritions_qty,
  country,
  imgUrl,
  price,
  price_info,
  isWeight,
  Active,
) {
  //console.log("addGoods: ", Article);
  return (dispatch) => {
    dispatch(addGoodsRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addGoods token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_goods({
          Article,
          Name,
          nutritions,
          desc,
          group_id,
          warehouse_id,
          Barcode,
          in_stock: in_stock,
          multiplicity,
          nutritions_qty,
          country,
          imgUrl,
          price: price,
          price_info: price_info,
          isWeight,
          Active,
        })
      })

      .then((json) => {
        //console.log("addGoods: ", json);

        const status = json.status
        const data = json.data.good

        //console.log("addGoods resp: ", status, data);

        dispatch(addGoodsSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addGoodsFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getCart actions
 */
export function getCartRequest() {
  return {
    type: GET_MY_CART_REQUEST,
  }
}
export function getCartSuccess(json, page) {
  return {
    type: GET_MY_CART_SUCCESS,
    payload: { json: json, page: page },
  }
}
export function getCartFailure(error) {
  return {
    type: GET_MY_CART_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getCart
 *
 */
export function getCart(page = 0) {
  //console.log("getCart 1");
  var params = {}
  if (page !== 0) {
    params = { page: page }
  }
  return (dispatch) => {
    dispatch(getCartRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getCart token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_cart({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getCart: ", json);

        const status = json.status
        const data = json.data.cart

        //console.log("getCart resp: ", status, data);

        dispatch(getCartSuccess(data, page))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getCartFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addToCart actions
 */
export function addToCartRequest() {
  return {
    type: ADD_TO_CART_REQUEST,
  }
}
export function addToCartSuccess(json) {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload: json,
  }
}
export function addToCartFailure(error) {
  return {
    type: ADD_TO_CART_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addToCart
 *
 */
export function addToCart(good_id, qty, price) {
  //console.log("addToCart 1: ", good_id, qty, price);
  return (dispatch) => {
    dispatch(addToCartRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addToCart token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_to_cart({
          token: token,
          good_id: good_id,
          qty: qty,
          price: price,
        })
      })

      .then((json) => {
        //console.log("addToCart: ", json);

        const status = json.status
        const data = json.data.cart

        //console.log("addToCart resp: ", status, data);

        dispatch(addToCartSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addToCartFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getFavorites actions
 */
export function getFavoritesRequest() {
  return {
    type: GET_FAVORITES_REQUEST,
  }
}
export function getFavoritesSuccess(json, page) {
  return {
    type: GET_FAVORITES_SUCCESS,
    payload: { json: json, page: page },
  }
}
export function getFavoritesFailure(error) {
  return {
    type: GET_FAVORITES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getFavorites
 *
 */
export function getFavorites(page = 0) {
  //console.log("getFavorites 1");
  var params = { page: page }
  return (dispatch) => {
    dispatch(getFavoritesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getFavorites token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_favorites({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getFavorites: ", json);

        const status = json.status
        const data = json.data.favorites

        //console.log("getFavorites resp: ", status, data);

        dispatch(getFavoritesSuccess(data, page))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getFavoritesFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addToFavorites actions
 */
export function addToFavoritesRequest() {
  return {
    type: ADD_TO_FAVORITES_REQUEST,
  }
}
export function addToFavoritesSuccess(json) {
  return {
    type: ADD_TO_FAVORITES_SUCCESS,
    payload: json,
  }
}
export function addToFavoritesFailure(error) {
  return {
    type: ADD_TO_FAVORITES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addToFavorites
 *
 */
export function addToFavorites(good_id, active = true) {
  //console.log("addToFavorites 1: ", good_id, active);
  return (dispatch) => {
    dispatch(addToFavoritesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addToFavorites token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_to_favorites({
          token: token,
          good_id: good_id,
          active: active,
        })
      })

      .then((json) => {
        //console.log("addToFavorites: ", json);

        const status = json.status
        const data = json.data.favorite

        //console.log("addToFavorites resp: ", status, data);

        dispatch(addToFavoritesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addToFavoritesFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addToOrders actions
 */
export function addToOrdersRequest() {
  return {
    type: ADD_TO_ORDERS_REQUEST,
  }
}
export function addToOrdersSuccess(json) {
  return {
    type: ADD_TO_ORDERS_SUCCESS,
    payload: json,
  }
}
export function addToOrdersFailure(error) {
  return {
    type: ADD_TO_ORDERS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addToOrders
 *
 */
export function addToOrders(
  phone,
  address,
  date_delivery,
  time_slot,
  delivery_variant,
  payment_method,
  cart,
  amount,
  delivery_amount,
) {
  //console.log("addToOrders 1: ", phone, address, date_delivery, time_slot, delivery_variant, payment_method, cart);
  let _cart = []

  for (var j = 0; j < cart.length; j++) {
    _cart.push({
      good_id: cart[j].good_id,
      price: cart[j].price,
      qty: cart[j].qty,
      user_id: cart[j].user_id,
    })
  }

  return (dispatch) => {
    dispatch(addToOrdersRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addToOrders token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_to_orders({
          phone: phone,
          address: address,
          date_delivery: date_delivery,
          time_slot: time_slot,
          delivery_variant: delivery_variant,
          payment_method: payment_method,
          cart: _cart,
          amount: amount,
          delivery_amount: delivery_amount,
        })
      })

      .then((json) => {
        //console.log("addToOrders: ", json);

        const status = json.status
        const data = json.data.order

        //console.log("addToOrders resp: ", status, data);

        dispatch(addToOrdersSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addToOrdersFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getOrders actions
 */
export function getOrdersRequest() {
  return {
    type: GET_ORDERS_REQUEST,
  }
}
export function getOrdersSuccess(json, page, dataobj, selectedStatusItemId) {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: { json, page, dataobj, selectedStatusItemId },
  }
}
export function getOrdersFailure(error) {
  return {
    type: GET_ORDERS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getOrders
 *
 */
export function getOrders(page = 0, status_id = null) {
  //console.log("getOrders 1");
  var params = {}
  if (page) {
    params = { ...params, page: page }
  }
  if (status_id) {
    params = { ...params, status_id: status_id }
  }
  return (dispatch) => {
    dispatch(getOrdersRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getOrders token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_orders({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getOrders: ", json);

        const status = json.status
        const data = json.data.orders
        const dataobj = json.data;

        //console.log("getOrders resp: ", status, data);

        dispatch(getOrdersSuccess(data, page, dataobj, status_id))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getOrdersFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getOrdersQty actions
 */
export function getOrdersQtyRequest() {
  return {
    type: GET_ORDERS_QTY_REQUEST,
  }
}
export function getOrdersQtySuccess(json) {
  return {
    type: GET_ORDERS_QTY_SUCCESS,
    payload: json,
  }
}
export function getOrdersQtyFailure(error) {
  return {
    type: GET_ORDERS_QTY_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getOrdersQty
 *
 */
export function getOrdersQty() {
  //console.log("getOrdersQty 1");
  return (dispatch) => {
    dispatch(getOrdersQtyRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getOrdersQty token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_orders_qty({
          token: token,
        })
      })

      .then((json) => {
        //console.log("getOrdersQty: ", json);

        const status = json.status
        const data = json.data.orders_qty
        const dataobj = json.data;

        //console.log("getOrdersQty resp: ", status, data);

        dispatch(getOrdersQtySuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getOrdersQtyFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## setOrderStatus actions
 */
export function setOrderStatusRequest() {
  return {
    type: SET_ORDER_STATUS_REQUEST,
  }
}
export function setOrderStatusSuccess(json) {
  return {
    type: SET_ORDER_STATUS_SUCCESS,
    payload: json,
  }
}
export function setOrderStatusFailure(error) {
  return {
    type: SET_ORDER_STATUS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## setOrderStatus
 *
 */
export function setOrderStatus(order_id, new_status) {
  //console.log('setOrderStatus 1: ', order_id, new_status)
  return (dispatch) => {
    dispatch(setOrderStatusRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        console.log('setOrderStatus token: ', token)
        return APIBackendFactory({ sessionToken: token }).update_order_status({
          token: token,
          order_id: order_id,
          new_status: new_status,
        })
      })

      .then((json) => {
        //console.log("setOrderStatus: ", json);

        const status = json.status
        const result = json.result
        const data = json.data.order

        //console.log('setOrderStatus resp: ', result, status, data)

        dispatch(setOrderStatusSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(setOrderStatusFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## deleteUserData actions
 */
export function deleteUserDataRequest() {
  return {
    type: DELETE_USER_DATA_REQUEST,
  }
}
export function deleteUserDataSuccess(json) {
  return {
    type: DELETE_USER_DATA_SUCCESS,
    payload: json,
  }
}
export function deleteUserDataFailure(error) {
  return {
    type: DELETE_USER_DATA_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## deleteUserData
 *
 */
export function deleteUserData() {
  //console.log("deleteUserData 1");
  return (dispatch) => {
    dispatch(getAreasRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("deleteUserData token: ", token);
        return APIBackendFactory({ sessionToken: token }).delete_user_data({
          token,
        })
      })

      .then((json) => {
        console.log('deleteUserData json: ', json)

        const status = json.status
        const data = json.data

        console.log('deleteUserData resp: ', status, data)

        dispatch(deleteUserDataSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(deleteUserDataFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## searchGoods actions
 */
export function searchGoodsRequest() {
  return {
    type: SEARCH_GOODS_REQUEST,
  }
}
export function searchGoodsSuccess(json, page) {
  return {
    type: SEARCH_GOODS_SUCCESS,
    payload: { json: json, page: page },
  }
}
export function searchGoodsFailure(error) {
  return {
    type: SEARCH_GOODS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## searchGoods
 *
 */
export function searchGoods(searchText, page = 0) {
  //console.log("searchGoods 1");
  var params = { text: searchText, page: page }
  return (dispatch) => {
    dispatch(searchGoodsRequest())
    // return BackendFactory().getFirebaseToken()
    //     .then(token => {
    //         //console.log("searchGoods token: ", token);
    //         return APIBackendFactory({ sessionToken: token }).get_goods(
    //             {
    //                 token: token,
    //                 params: params,
    //             });
    //     })
    return APIBackendFactory()
      .get_goods({
        params: params,
      })

      .then((json) => {
        //console.log("searchGoods: ", json);

        const status = json.status
        const data = json.data.goods

        //console.log("searchGoods resp: ", status, data);

        dispatch(searchGoodsSuccess(data, page))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(searchGoodsFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

export function logoutClear() {
  return {
    type: LOGOUT_CLEAR_SUCCESS,
  }
}

/**
 * ## getMessages actions
 */
export function getMessagesRequest() {
  return {
    type: GET_MESSAGES_REQUEST,
  }
}
export function getMessagesSuccess(json, page, dataobj, selectedUserItemId) {
  return {
    type: GET_MESSAGES_SUCCESS,
    payload: { json: json, page: page, dataobj, selectedUserItemId },
  }
}
export function getMessagesFailure(error) {
  return {
    type: GET_MESSAGES_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getMessages
 *
 */
export function getMessages(user_id, page = 0) {
  //console.log("getMessages 1");
  var params = { user_id: user_id, }
  if (page !== 0) {
    params = { ...params, page: page };
  }
  return (dispatch) => {
    dispatch(getMessagesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getMessages token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_messages({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getMessages: ", json);

        const status = json.status
        const data = json.data.messages
        const dataobj = json.data

        //console.log("getMessages resp: ", status, data);

        dispatch(getMessagesSuccess(data, page, dataobj, user_id))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getMessagesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## setMessage actions
 */
export function setMessageRequest() {
  return {
    type: SET_MESSAGE_REQUEST,
  }
}
export function setMessageSuccess(json) {
  return {
    type: SET_MESSAGE_SUCCESS,
    payload: json,
  }
}
export function setMessageFailure(error) {
  return {
    type: SET_MESSAGE_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## setMessage
 *
 */
export function setMessage(message_id, message_text, active = true) {
  //console.log('setMessage 1: ', message_id, active)
  return (dispatch) => {
    dispatch(setMessageRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        console.log('setMessage token: ', token)
        return APIBackendFactory({ sessionToken: token }).set_message({
          token: token,
          message_id: message_id,
          message_text: message_text,
          active: active,
        })
      })

      .then((json) => {
        //console.log("setMessage: ", json);

        const status = json.status
        const result = json.result
        const data = json.data.message

        console.log('setMessage resp: ', result, status, data)

        dispatch(setMessageSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(setMessageFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## getChat actions
 */
export function getChatRequest() {
  return {
    type: GET_CHAT_REQUEST,
  }
}
export function getChatSuccess(json, page, dataobj, selectedUserItemId) {
  return {
    type: GET_CHAT_SUCCESS,
    payload: { json: json, page: page, dataobj, selectedUserItemId },
  }
}
export function getChatFailure(error) {
  return {
    type: GET_CHAT_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getChat
 *
 */
export function getChat(user_id, page = 0) {
  //console.log("getChat 1");
  var params = { user_id: user_id, }
  if (page !== 0) {
    params = { ...params, page: page };
  }
  return (dispatch) => {
    dispatch(getChatRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getChat token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_chat({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getChat: ", json);

        const status = json.status
        const data = json.data.chat
        const dataobj = json.data

        //console.log("getChat resp: ", page, status, data);

        dispatch(getChatSuccess(data, page, dataobj, user_id))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getChatFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## setChatRead actions
 */
export function setChatReadRequest() {
  return {
    type: SET_CHAT_READ_REQUEST,
  }
}
export function setChatReadSuccess(json, page, dataobj, selectedUserItemId) {
  return {
    type: SET_CHAT_READ_SUCCESS,
    payload: { json: json, page: page, dataobj, selectedUserItemId },
  }
}
export function setChatReadFailure(error) {
  return {
    type: SET_CHAT_READ_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## setChatRead
 *
 */
export function setChatRead(user_id, page = 0) {
  //console.log("setChatRead 1: ", chat_id, active);
  var params = { user_id: user_id, }
  if (page !== 0) {
    params = { ...params, page: page };
  }
  return (dispatch) => {
    dispatch(setChatReadRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("setChatRead token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_chat_read({
          token: token,
          ...params,
        })
      })

      .then((json) => {
        //console.log("setChatRead: ", json);

        const status = json.status
        const result = json.result
        const data = json.data.chat
        const dataobj = json.data

        console.log('setChatRead resp: ', result, status, data)

        dispatch(setChatReadSuccess(data, page, dataobj, user_id))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(setChatReadFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## addToChat actions
 */
export function addToChatRequest() {
  return {
    type: ADD_TO_CHAT_REQUEST,
  }
}
export function addToChatSuccess(json) {
  return {
    type: ADD_TO_CHAT_SUCCESS,
    payload: json,
  }
}
export function addToChatFailure(error) {
  return {
    type: ADD_TO_CHAT_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addToChat
 *
 */
export function addToChat(user_id, message_text) {
  //console.log("addToChat 1: ", message_text);
  return (dispatch) => {
    dispatch(addToChatRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addToChat token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_to_chat({
          token: token,
          user_id: user_id,
          message_text: message_text,
        })
      })

      .then((json) => {
        //console.log("addToChat: ", json);

        const status = json.status
        const data = json.data.chat

        //console.log("addToChat resp: ", status, data);

        dispatch(addToChatSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addToChatFailure(error))
        //return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## setChat actions
 */
export function setChatRequest() {
  return {
    type: SET_CHAT_REQUEST,
  }
}
export function setChatSuccess(json) {
  return {
    type: SET_CHAT_SUCCESS,
    payload: json,
  }
}
export function setChatFailure(error) {
  return {
    type: SET_CHAT_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## setChat
 *
 */
export function setChat(chat_id, message_text, active = true, is_reply = true) {
  //console.log('setChat 1: ', chat_id, active)
  return (dispatch) => {
    dispatch(setChatRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        console.log('setChat token: ', token)
        return APIBackendFactory({ sessionToken: token }).set_chat({
          token: token,
          chat_id: chat_id,
          message_text: message_text,
          active: active,
          is_reply: is_reply,
        })
      })

      .then((json) => {
        //console.log("setChat: ", json);

        const status = json.status
        const result = json.result
        const data = json.data.chat

        console.log('setChat resp: ', result, status, data)

        dispatch(setChatSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(setChatFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## addToMessages actions
 */
export function addToMessagesRequest() {
  return {
    type: ADD_MESSAGE_REQUEST,
  }
}
export function addToMessagesSuccess(json) {
  return {
    type: ADD_MESSAGE_SUCCESS,
    payload: json,
  }
}
export function addToMessagesFailure(error) {
  return {
    type: ADD_MESSAGE_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addToMessages
 *
 */
export function addToMessages(user_id, message_text) {
  //console.log("addToMessages 1: ", message_text);
  return (dispatch) => {
    dispatch(addToMessagesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addToMessages token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_new_message({
          token: token,
          user_id: user_id,
          message_text: message_text,
        })
      })

      .then((json) => {
        //console.log("addToMessages: ", json);

        const status = json.status
        const data = json.data.message

        //console.log("addToMessages resp: ", status, data);

        dispatch(addToMessagesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addToMessagesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getDashboardQty actions
 */
export function getDashboardQtyRequest() {
  return {
    type: GET_DASHBOARD_QTY_REQUEST,
  }
}
export function getDashboardQtySuccess(dataobj) {
  return {
    type: GET_DASHBOARD_QTY_SUCCESS,
    payload: dataobj,
  }
}
export function getDashboardQtyFailure(error) {
  return {
    type: GET_DASHBOARD_QTY_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getDashboardQty
 *
 */
export function getDashboardQty() {
  //console.log("getDashboardQty 1");
  var params = {}

  return (dispatch) => {
    dispatch(getDashboardQtyRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getDashboardQty token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_dashboard_qty({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getDashboardQty: ", json);

        const status = json.status
        const dataobj = json.data

        //console.log("getDashboardQty resp: ", status, dataobj);

        dispatch(getDashboardQtySuccess(dataobj))

        return Promise.resolve({ status, dataobj })
      })

      .catch((error) => {
        dispatch(getDashboardQtyFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getPricelistsByGoodID actions
 */
export function getPricelistsByGoodIDRequest() {
  return {
    type: GET_PRICELISTS_BY_GOOD_ID_REQUEST,
  }
}
export function getPricelistsByGoodIDSuccess(json) {
  return {
    type: GET_PRICELISTS_BY_GOOD_ID_SUCCESS,
    payload: json,
  }
}
export function getPricelistsByGoodIDFailure(error) {
  return {
    type: GET_PRICELISTS_BY_GOOD_ID_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getPricelistsByGoodID
 *
 */
export function getPricelistsByGoodID(good_id) {
  //console.log("getPricelistsByGoodID 1");
  var params = { id: good_id }
  return (dispatch) => {
    dispatch(getPricelistsByGoodIDRequest())
    return BackendFactory().getFirebaseToken()
      .then(token => {
        //console.log("getPricelistsByGoodID token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_pricelists_by_good_id(
          {
            token: token,
            params: params,
          });
      })

      .then((json) => {
        //console.log("getPricelistsByGoodID: ", json);

        const status = json.status
        const data = json.data.pricelists

        //console.log("getPricelistsByGoodID resp: ", status, data);

        dispatch(getPricelistsByGoodIDSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getPricelistsByGoodIDFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## uploadImages actions
 */
export function uploadImagesRequest() {
  return {
    type: SET_UPLOAD_IMAGE_REQUEST,
  }
}
export function uploadImagesSuccess(json) {
  return {
    type: SET_UPLOAD_IMAGE_SUCCESS,
    payload: json,
  }
}
export function uploadImagesFailure(error) {
  return {
    type: SET_UPLOAD_IMAGE_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## uploadImages
 *
 */
export function uploadImages(
  imageList,
) {
  //console.log("uploadImages: ", imageList);
  return (dispatch) => {
    dispatch(uploadImagesRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("uploadImages token: ", token);
        return APIBackendFactory({ sessionToken: token }).set_upload_image({
          imageList
        })
      })

      .then((json) => {
        //console.log("uploadImages: ", json);

        const status = json.status
        const data = json.data.image_paths

        console.log("uploadImages resp: ", status, data);

        dispatch(uploadImagesSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(uploadImagesFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## getBanners actions
 */
export function getBannersRequest() {
  return {
    type: GET_BANNERS_REQUEST,
  }
}
export function getBannersSuccess(json, page, dataobj) {
  return {
    type: GET_BANNERS_SUCCESS,
    payload: { json: json, page: page, dataobj },
  }
}
export function getBannersFailure(error) {
  return {
    type: GET_BANNERS_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## getBanners
 *
 */
export function getBanners(page = 0) {
  //console.log("getBanners 1");
  var params = { }
  if (page !== 0) {
    params = { ...params, page: page };
  }
  return (dispatch) => {
    dispatch(getBannersRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("getBanners token: ", token);
        return APIBackendFactory({ sessionToken: token }).get_banners({
          token: token,
          params: params,
        })
      })

      .then((json) => {
        //console.log("getBanners: ", json);

        const status = json.status
        const data = json.data.banners
        const dataobj = json.data

        //console.log("getBanners resp: ", status, data);

        dispatch(getBannersSuccess(data, page, dataobj))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(getBannersFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}

/**
 * ## setBanner actions
 */
export function setBannerRequest() {
  return {
    type: SET_BANNER_REQUEST,
  }
}
export function setBannerSuccess(json) {
  return {
    type: SET_BANNER_SUCCESS,
    payload: json,
  }
}
export function setBannerFailure(error) {
  return {
    type: SET_BANNER_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## setBanner
 *
 */
export function setBanner(banner_id, capition, html_desc, img, link, link_text, banner_date, Active = true) {
  //console.log('setBanner 1: ', banner_id, Active)
  return (dispatch) => {
    dispatch(setMessageRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        console.log('setBanner token: ', token)
        return APIBackendFactory({ sessionToken: token }).set_banner({
          token: token,
          banner_id: banner_id,
          capition: capition,
          html_desc: html_desc, 
          img: img,
          link: link, 
          link_text: link_text,
          banner_date: banner_date,
          Active: Active,
        })
      })

      .then((json) => {
        //console.log("setBanner: ", json);

        const status = json.status
        const result = json.result
        const data = json.data.banner

        console.log('setBanner resp: ', result, status, data)

        dispatch(setBannerSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(setBannerFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}


/**
 * ## addToBanners actions
 */
export function addToBannersRequest() {
  return {
    type: ADD_BANNER_REQUEST,
  }
}
export function addToBannersSuccess(json) {
  return {
    type: ADD_BANNER_SUCCESS,
    payload: json,
  }
}
export function addToBannersFailure(error) {
  return {
    type: ADD_BANNER_FAILURE,
    payload: _.isUndefined(error) ? null : error,
  }
}

/**
 * ## addToBanners
 *
 */
export function addToBanners(capition, html_desc, img, link, link_text, banner_date, Active = true) {
  //console.log("addToBanners 1: ", capition);
  return (dispatch) => {
    dispatch(addToBannersRequest())
    return BackendFactory()
      .getFirebaseToken()
      .then((token) => {
        //console.log("addToBanners token: ", token);
        return APIBackendFactory({ sessionToken: token }).add_new_banner({
          token: token,
          capition: capition,
          html_desc: html_desc,
          img: img, 
          link: link, 
          link_text: link_text, 
          banner_date: banner_date,
          Active: Active,
        })
      })

      .then((json) => {
        //console.log("addToBanners: ", json);

        const status = json.status
        const data = json.data.banner

        //console.log("addToBanners resp: ", status, data);

        dispatch(addToBannersSuccess(data))

        return Promise.resolve({ status, data })
      })

      .catch((error) => {
        dispatch(addToBannersFailure(error))
        return Promise.reject(error);
        // AppNavigation.navigate("ConnectionErr", {
        //   error: error.message,
        // });
      })
  }
}