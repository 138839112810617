import * as React from 'react'
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'

import AuthContext from './components/auth/AuthContext'
import BackendFactory from './lib/BackendFactory'
import { initializeApp } from 'firebase/app'
import { onAuthStateChanged, User, getAuth } from 'firebase/auth'

import { useAppDispatch, useAppSelector } from './hooks'
import { checkOrCreateNewUser } from './reducers/auth/authActions'
import { CreatedUser } from './redux/selectors'

import Auth from './Auth'
import Main from './Main'

import { firebaseConfig } from './config/firebaseConfig'


type State = {
  isLoading: boolean
  isSignout: boolean
  userToken: undefined | string
}

type Action =
  | { type: 'RESTORE_TOKEN'; token: undefined | string }
  | { type: 'SIGN_IN'; token: string }
  | { type: 'SIGN_OUT' }

export default function App() {
  const app_dispatch = useAppDispatch()
  const createdUser = useAppSelector(CreatedUser)

  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          }
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          }
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: undefined,
          }
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: undefined,
    },
  )

  // Handle user state changes
  function _onAuthStateChanged(user: User | null) {
    console.log('onAuthStateChanged 1: ', user)
    if (user) {
      if (user.providerData[0].providerId === 'password') {
        if (user.emailVerified) {
          dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' })
        } else {
          dispatch({ type: 'SIGN_OUT' })
        }
      } else {
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' })
      }
    } else {
      //dispatch({type: 'RESTORE_TOKEN', token: 'dummy-auth-token'}); // для теста изменить для статуса "авторизован"
      dispatch({ type: 'SIGN_OUT' })
    } //token: 'dummy-auth-token' / undefined
    //console.log("onAuthStateChanged: ", user, state.userToken);
    if (user) {
      //console.log('_onAuthStateChanged: ', user.uid)
      app_dispatch(checkOrCreateNewUser(user.uid, '', ''))
    }
  }

  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)

  React.useEffect(() => {
    // const timer = setTimeout(() => {
    //   dispatch({type: 'RESTORE_TOKEN', token: undefined});
    // }, 1000);

    // return () => clearTimeout(timer);
    let user = auth.currentUser
    if (!user) {
      dispatch({ type: 'SIGN_OUT' })
    }
    if (user) {
      //console.log('React.useEffect: ', user.uid)
      app_dispatch(checkOrCreateNewUser(user.uid, '', ''))
    }

    const subscriber = onAuthStateChanged(auth, _onAuthStateChanged)

    return subscriber // unsubscribe on unmount
  }, [])

  const isSignedIn = state.userToken !== undefined

  const authContext = React.useMemo(
    () => ({
      isSignedIn,
      signIn: () => dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' }),
      signOut: () => dispatch({ type: 'SIGN_OUT' }),
    }),
    [isSignedIn],
  )

  return (
      <AuthContext.Provider value={authContext}>
        {!isSignedIn ? <Auth></Auth> : <Main></Main>}
      </AuthContext.Provider>
  )
}
