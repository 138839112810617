/**
 * # storedataReducer.js
 *
 * The reducer for all the actions from the various log states
 */
"use strict";
/**
 * ## Imports
 *
 * InitialState
 */
import { REHYDRATE } from "redux-persist";
import CONFIG from "../../config/config";

import InitialState from "./storedataInitialState";

/**
 * Device actions to test
 */
const {
  GET_AREAS_REQUEST,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,

  GET_WAREHOUSES_REQUEST,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,

  GET_TIME_SLOTS_REQUEST,
  GET_TIME_SLOTS_SUCCESS,
  GET_TIME_SLOTS_FAILURE,

  SET_TIME_SLOTS_REQUEST,
  SET_TIME_SLOTS_SUCCESS,
  SET_TIME_SLOTS_FAILURE,

  ADD_TIME_SLOTS_REQUEST,
  ADD_TIME_SLOTS_SUCCESS,
  ADD_TIME_SLOTS_FAILURE,

  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,

  SET_PAYMENT_METHODS_REQUEST,
  SET_PAYMENT_METHODS_SUCCESS,
  SET_PAYMENT_METHODS_FAILURE,

  ADD_PAYMENT_METHODS_REQUEST,
  ADD_PAYMENT_METHODS_SUCCESS,
  ADD_PAYMENT_METHODS_FAILURE,

  GET_DELIVERY_VARIANTS_REQUEST,
  GET_DELIVERY_VARIANTS_SUCCESS,
  GET_DELIVERY_VARIANTS_FAILURE,

  SET_DELIVERY_VARIANTS_REQUEST,
  SET_DELIVERY_VARIANTS_SUCCESS,
  SET_DELIVERY_VARIANTS_FAILURE,

  ADD_DELIVERY_VARIANTS_REQUEST,
  ADD_DELIVERY_VARIANTS_SUCCESS,
  ADD_DELIVERY_VARIANTS_FAILURE,

  GET_PRICE_TYPES_REQUEST,
  GET_PRICE_TYPES_SUCCESS,
  GET_PRICE_TYPES_FAILURE,

  SET_PRICE_TYPES_REQUEST,
  SET_PRICE_TYPES_SUCCESS,
  SET_PRICE_TYPES_FAILURE,

  ADD_PRICE_TYPES_REQUEST,
  ADD_PRICE_TYPES_SUCCESS,
  ADD_PRICE_TYPES_FAILURE,

  GET_ALL_GROUPS_REQUEST,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAILURE,

  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,

  GET_ALL_PRICE_TYPES_REQUEST,
  GET_ALL_PRICE_TYPES_SUCCESS,
  GET_ALL_PRICE_TYPES_FAILURE,

  GET_GOODS_REQUEST,
  GET_GOODS_SUCCESS,
  GET_GOODS_FAILURE,

  SET_GOODS_REQUEST,
  SET_GOODS_SUCCESS,
  SET_GOODS_FAILURE,

  ADD_GOODS_REQUEST,
  ADD_GOODS_SUCCESS,
  ADD_GOODS_FAILURE,

  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,

  SET_GROUPS_REQUEST,
  SET_GROUPS_SUCCESS,
  SET_GROUPS_FAILURE,

  ADD_GROUPS_REQUEST,
  ADD_GROUPS_SUCCESS,
  ADD_GROUPS_FAILURE,

  GET_GROUPS_DETAILS_REQUEST,
  GET_GROUPS_DETAILS_SUCCESS,
  GET_GROUPS_DETAILS_FAILURE,

  GET_GOODS_DETAILS_REQUEST,
  GET_GOODS_DETAILS_SUCCESS,
  GET_GOODS_DETAILS_FAILURE,

  GET_MY_CART_REQUEST,
  GET_MY_CART_SUCCESS,
  GET_MY_CART_FAILURE,

  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,

  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAILURE,

  ADD_TO_FAVORITES_REQUEST,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILURE,

  ADD_TO_ORDERS_REQUEST,
  ADD_TO_ORDERS_SUCCESS,
  ADD_TO_ORDERS_FAILURE,

  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,

  GET_ORDERS_QTY_REQUEST,
  GET_ORDERS_QTY_SUCCESS,
  GET_ORDERS_QTY_FAILURE,

  SET_ORDER_STATUS_REQUEST,
  SET_ORDER_STATUS_SUCCESS,
  SET_ORDER_STATUS_FAILURE,

  DELETE_USER_DATA_REQUEST,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_FAILURE,

  SEARCH_GOODS_REQUEST,
  SEARCH_GOODS_SUCCESS,
  SEARCH_GOODS_FAILURE,

  LOGOUT_CLEAR_REQUEST,
  LOGOUT_CLEAR_SUCCESS,
  LOGOUT_CLEAR_FAILURE,

  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,

  SET_MESSAGE_REQUEST,
  SET_MESSAGE_SUCCESS,
  SET_MESSAGE_FAILURE,

  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE,

  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAILURE,

  SET_CHAT_READ_REQUEST,
  SET_CHAT_READ_SUCCESS,
  SET_CHAT_READ_FAILURE,

  ADD_TO_CHAT_REQUEST,
  ADD_TO_CHAT_SUCCESS,
  ADD_TO_CHAT_FAILURE,

  SET_CHAT_REQUEST,
  SET_CHAT_SUCCESS,
  SET_CHAT_FAILURE,

  GET_DASHBOARD_QTY_REQUEST,
  GET_DASHBOARD_QTY_SUCCESS,
  GET_DASHBOARD_QTY_FAILURE,

  GET_PRICELISTS_BY_GOOD_ID_REQUEST,
  GET_PRICELISTS_BY_GOOD_ID_SUCCESS,
  GET_PRICELISTS_BY_GOOD_ID_FAILURE,

  SET_UPLOAD_IMAGE_REQUEST,
  SET_UPLOAD_IMAGE_SUCCESS,
  SET_UPLOAD_IMAGE_FAILURE,

  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILURE,

  SET_BANNER_REQUEST,
  SET_BANNER_SUCCESS,
  SET_BANNER_FAILURE,

  ADD_BANNER_REQUEST,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAILURE,

} = require("../../config/constants").default;

const initialState = new InitialState();

/**
 * ## storedataReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function storedataReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.merge(state);

  //console.log("storedataReducer: ", state);

  switch (action.type) {
    case GET_AREAS_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case GET_AREAS_SUCCESS:
      const areas = action.payload;
      let areasDropDownItems = [];
      for (var i = 0; i < areas.length; i++) {
        areasDropDownItems.push({ label: areas[i].name, value: areas[i]._id });
      }
      //console.log("GET_AREAS_SUCCESS: ", areasDropDownItems);
      return state
        .set("isFetching", false)
        .set("areasDropDownItems", areasDropDownItems)
        .set("areas", [...areas]);

    case GET_AREAS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_WAREHOUSES_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case GET_WAREHOUSES_SUCCESS:
      const warehouses = action.payload;

      //console.log("GET_WAREHOUSES_SUCCESS: ", warehouses);
      return state
        .set("isFetching", false)
        .set("warehouses", [...warehouses]);

    case GET_WAREHOUSES_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_TIME_SLOTS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_TIME_SLOTS_SUCCESS:
      const timeslots = action.payload.json;
      const page9 = action.payload.page;
      const dataobj9 = action.payload.dataobj;
      const total_results9 = dataobj9.total_results; // in query
      const total_rows9 = dataobj9.total_rows; // in table
      const entries_per_page9 = dataobj9.entries_per_page;

      let timeslotsState = [];
      timeslotsState = timeslotsState.concat(timeslots);
      let timeslotsFiltered = [...timeslotsState];
      const lastTimeSlotPage = page9;

      return state
        .set("isFetching", false)
        .set("lastTimeSlotPage", lastTimeSlotPage)
        .set("totalTimeSlotResultRows", total_results9)
        .set("totalTimeSlotRows", total_rows9)
        .set("entriesPerPageTimeSlotRows", entries_per_page9)
        .set("endReachedTimeSlotPage", false)
        .set("timeSlots", [...timeslotsState])
        .set("timeSlotsFiltered", [...timeslotsFiltered]);

    case GET_TIME_SLOTS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SET_TIME_SLOTS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_TIME_SLOTS_SUCCESS:
      const timeslots1 = action.payload;

      var timeslotsState1 = state.timeSlots.map((value) => ({ ...value }));
      for (var j = 0; j < timeslotsState1.length; j++) {
        if (timeslots1._id === timeslotsState1[j]._id) {
          timeslotsState1[j] = { ...timeslots1 };
        }
      }
      let timeslotsFiltered1 = [...timeslotsState1];

      return state
        .set("isFetching", false)
        .set("timeSlots", [...timeslotsState1])
        .set("success", "Успешно сохранено")
        .set("timeSlotsFiltered", [...timeslotsFiltered1]);

    case SET_TIME_SLOTS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case ADD_TIME_SLOTS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case ADD_TIME_SLOTS_SUCCESS:
      const timeslots2 = action.payload;

      var findItem3 = false;
      var timeslotsState2 = state.timeSlots.map((value) => ({ ...value }));
      for (var j = 0; j < timeslotsState2.length; j++) {
        if (timeslots2._id === timeslotsState2[j]._id) {
          timeslotsState2[j] = { ...timeslots2 };
          findItem3 = true;
        }
      }
      if (!findItem3) {
        timeslotsState2.push(timeslots2);
      }

      let timeslotsFiltered2 = [...timeslotsState2];

      return state
        .set("isFetching", false)
        .set("timeSlots", [...timeslotsState2])
        .set("success", "Успешно сохранено")
        .set("timeSlotsFiltered", [...timeslotsFiltered2]);

    case ADD_TIME_SLOTS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case GET_PAYMENT_METHODS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_PAYMENT_METHODS_SUCCESS:
      const payment_methods = action.payload.json;
      const page7 = action.payload.page;
      const dataobj7 = action.payload.dataobj;
      const total_results7 = dataobj7.total_results; // in query
      const total_rows7 = dataobj7.total_rows; // in table
      const entries_per_page7 = dataobj7.entries_per_page;

      let paymentMethodsState = [];
      paymentMethodsState = paymentMethodsState.concat(payment_methods);
      let paymentMethodsFiltered = [...paymentMethodsState];
      const lastPaymentMethodPage = page7;

      return state
        .set("isFetching", false)
        .set("lastPaymentMethodPage", lastPaymentMethodPage)
        .set("totalPaymentMethodResultRows", total_results7)
        .set("totalPaymentMethodRows", total_rows7)
        .set("entriesPerPagePaymentMethodRows", entries_per_page7)
        .set("endReachedPaymentMethodPage", false)
        .set("paymentMethods", [...paymentMethodsState])
        .set("paymentMethodsFiltered", [...paymentMethodsFiltered]);

    case GET_PAYMENT_METHODS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);


    case SET_PAYMENT_METHODS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_PAYMENT_METHODS_SUCCESS:
      const payment_methods1 = action.payload;

      var payment_methodsState = state.paymentMethods.map((value) => ({ ...value }));
      for (var j = 0; j < payment_methodsState.length; j++) {
        if (payment_methods1._id === payment_methodsState[j]._id) {
          payment_methodsState[j] = { ...payment_methods1 };
        }
      }
      let payment_methodsFiltered3 = [...payment_methodsState];

      //console.log("SET_DELIVERY_VARIANTS_SUCCESS: ", goodsFiltered3);
      return state
        .set("isFetching", false)
        .set("paymentMethods", [...payment_methodsState])
        .set("success", "Успешно сохранено")
        .set("paymentMethodsFiltered", [...payment_methodsFiltered3]);

    case SET_PAYMENT_METHODS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case ADD_PAYMENT_METHODS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case ADD_PAYMENT_METHODS_SUCCESS:
      const payment_methods2 = action.payload;

      var findItem2 = false;
      var payment_methodsState1 = state.paymentMethods.map((value) => ({ ...value }));
      for (var j = 0; j < payment_methodsState1.length; j++) {
        if (payment_methods2._id === payment_methodsState1[j]._id) {
          payment_methodsState1[j] = { ...payment_methods2 };
          findItem2 = true;
        }
      }
      if (!findItem2) {
        payment_methodsState1.push(payment_methods2);
      }

      let payment_methodsFiltered1 = [...payment_methodsState1];

      return state
        .set("isFetching", false)
        .set("paymentMethods", [...payment_methodsState1])
        .set("success", "Успешно сохранено")
        .set("paymentMethodsFiltered", [...payment_methodsFiltered1]);

    case ADD_PAYMENT_METHODS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case GET_DELIVERY_VARIANTS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_DELIVERY_VARIANTS_SUCCESS:
      //console.log("GET_DELIVERY_VARIANTS_SUCCESS: ", dataobj);
      const delivery_variants = action.payload.json;
      const page3 = action.payload.page;
      const dataobj3 = action.payload.dataobj;
      const total_results3 = dataobj3.total_results; // in query
      const total_rows3 = dataobj3.total_rows; // in table
      const entries_per_page3 = dataobj3.entries_per_page

      //console.log("GET_DELIVERY_VARIANTS_SUCCESS: ", dataobj2);

      let deliveryVariantsState = [];
      deliveryVariantsState = deliveryVariantsState.concat(delivery_variants);
      let deliveryVariantsFiltered = [...deliveryVariantsState];
      const lastDeliveryVariantsPage = page3;

      //console.log("GET_DELIVERY_VARIANTS_SUCCESS: ", deliveryVariantsFiltered);
      return state
        .set("isFetching", false)
        .set("lastDeliveryVariantPage", lastDeliveryVariantsPage)
        .set("totalDeliveryVariantResultRows", total_results3)
        .set("totalDeliveryVariantRows", total_rows3)
        .set("entriesPerPageDeliveryVariantRows", entries_per_page3)
        .set("endReachedDeliveryVariantPage", false)
        .set("deliveryVariants", [...delivery_variants])
        .set("deliveryVariantsFiltered", [...deliveryVariantsFiltered]);

    case GET_DELIVERY_VARIANTS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);


    case SET_DELIVERY_VARIANTS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_DELIVERY_VARIANTS_SUCCESS:
      const delivery_variants1 = action.payload;
      //console.log("SET_DELIVERY_VARIANTS_SUCCESS: ", delivery_variants1);

      var delivery_variantsState = state.deliveryVariants.map((value) => ({ ...value }));
      for (var j = 0; j < delivery_variantsState.length; j++) {
        if (delivery_variants1._id === delivery_variantsState[j]._id) {
          delivery_variantsState[j] = { ...delivery_variants1 };
        }
      }
      let delivery_variantsFiltered3 = [...delivery_variantsState];

      //console.log("SET_DELIVERY_VARIANTS_SUCCESS: ", goodsFiltered3);
      return state
        .set("isFetching", false)
        .set("deliveryVariants", [...delivery_variantsState])
        .set("success", "Успешно сохранено")
        .set("deliveryVariantsFiltered", [...delivery_variantsFiltered3]);

    case SET_DELIVERY_VARIANTS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case ADD_DELIVERY_VARIANTS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case ADD_DELIVERY_VARIANTS_SUCCESS:
      const delivery_variants2 = action.payload;
      //console.log("ADD_DELIVERY_VARIANTS_SUCCESS: ", delivery_variants2);

      var findItem1 = false;
      var delivery_variantsState1 = state.deliveryVariants.map((value) => ({ ...value }));
      for (var j = 0; j < delivery_variantsState1.length; j++) {
        if (delivery_variants2._id === delivery_variantsState1[j]._id) {
          delivery_variantsState1[j] = { ...delivery_variants2 };
          findItem1 = true;
        }
      }
      if (!findItem1) {
        delivery_variantsState1.push(delivery_variants2);
      }

      let delivery_variantsFiltered1 = [...delivery_variantsState1];

      return state
        .set("isFetching", false)
        .set("deliveryVariants", [...delivery_variantsState1])
        .set("success", "Успешно сохранено")
        .set("deliveryVariantsFiltered", [...delivery_variantsFiltered1]);

    case ADD_DELIVERY_VARIANTS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);



    case GET_PRICE_TYPES_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_PRICE_TYPES_SUCCESS:
      //console.log("GET_PRICE_TYPES_SUCCESS: ", dataobj);
      const price_types = action.payload.json;
      const page12 = action.payload.page;
      const dataobj12 = action.payload.dataobj;
      const total_results12 = dataobj12.total_results; // in query
      const total_rows12 = dataobj12.total_rows; // in table
      const entries_per_page12 = dataobj12.entries_per_page

      //console.log("GET_PRICE_TYPES_SUCCESS: ", dataobj2);

      let priceTypesState = [];
      priceTypesState = priceTypesState.concat(price_types);
      let priceTypesFiltered = [...priceTypesState];
      const lastPriceTypePage = page12;

      //console.log("GET_PRICE_TYPES_SUCCESS: ", deliveryVariantsFiltered);
      return state
        .set("isFetching", false)
        .set("lastPriceTypePage", lastPriceTypePage)
        .set("totalPriceTypeResultRows", total_results12)
        .set("totalPriceTypeRows", total_rows12)
        .set("entriesPerPagePriceTypeRows", entries_per_page12)
        .set("endReachedPriceTypePage", false)
        .set("priceTypes", [...price_types])
        .set("priceTypesFiltered", [...priceTypesFiltered]);

    case GET_PRICE_TYPES_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);


    case SET_PRICE_TYPES_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_PRICE_TYPES_SUCCESS:
      const price_types1 = action.payload;
      //console.log("SET_PRICE_TYPES_SUCCESS: ", price_types1);

      var price_typesState = state.priceTypes.map((value) => ({ ...value }));
      for (var j = 0; j < price_typesState.length; j++) {
        if (price_types1._id === price_typesState[j]._id) {
          price_typesState[j] = { ...price_types1 };
        }
      }
      let price_typesFiltered1 = [...price_typesState];

      //console.log("SET_PRICE_TYPES_SUCCESS: ", price_typesFiltered1);
      return state
        .set("isFetching", false)
        .set("priceTypes", [...price_typesState])
        .set("success", "Успешно сохранено")
        .set("priceTypesFiltered", [...price_typesFiltered1]);

    case SET_PRICE_TYPES_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case ADD_PRICE_TYPES_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case ADD_PRICE_TYPES_SUCCESS:
      const price_types2 = action.payload;
      //console.log("ADD_PRICE_TYPES_SUCCESS: ", price_types2);

      var findItem1 = false;
      var price_typesState1 = state.priceTypes.map((value) => ({ ...value }));
      for (var j = 0; j < price_typesState1.length; j++) {
        if (price_types2._id === price_typesState1[j]._id) {
          price_typesState1[j] = { ...price_types2 };
          findItem1 = true;
        }
      }
      if (!findItem1) {
        price_typesState1.push(price_types2);
      }

      let price_typesFiltered2 = [...price_typesState1];

      return state
        .set("isFetching", false)
        .set("priceTypes", [...price_typesState1])
        .set("success", "Успешно сохранено")
        .set("priceTypesFiltered", [...price_typesFiltered2]);

    case ADD_PRICE_TYPES_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case GET_ALL_GROUPS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_ALL_GROUPS_SUCCESS:
      const groups = action.payload;
      //console.log("GET_ALL_GROUPS_SUCCESS: ", groups);
      return state
        .set("isFetching", false)
        .set("allGroups", [...groups])
        .set("allGroupsFiltered", [...groups]);

    case GET_ALL_GROUPS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_ALL_PRICE_TYPES_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_ALL_PRICE_TYPES_SUCCESS:
      const allpricetypes = action.payload;
      //console.log("GET_ALL_PRICE_TYPES_SUCCESS: ", allpricetypes);
      return state
        .set("isFetching", false)
        .set("allPriceTypes", [...allpricetypes])
        .set("allPriceTypesFiltered", [...allpricetypes]);

    case GET_ALL_PRICE_TYPES_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_ALL_USERS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_ALL_USERS_SUCCESS:
      const users1 = action.payload;
      //console.log("GET_ALL_USERS_SUCCESS: ", groups);
      return state
        .set("isFetching", false)
        .set("allUsers", [...users1])
        .set("allUsersFiltered", [...users1]);

    case GET_ALL_USERS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_GROUPS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_GROUPS_SUCCESS:
      //console.log("GET_GROUPS_SUCCESS: ", dataobj);
      const groups2 = action.payload.json;
      const page2 = action.payload.page;
      const dataobj2 = action.payload.dataobj;
      const total_results2 = dataobj2.total_results; // in query
      const total_rows2 = dataobj2.total_rows; // in table
      const entries_per_page2 = dataobj2.entries_per_page

      //console.log("GET_GOODS_SUCCESS: ", dataobj2);

      let groupsState2 = [];
      groupsState2 = groupsState2.concat(groups2);
      let groupsFiltered2 = [...groupsState2];
      const lastGroupsPage2 = page2;

      //console.log("GET_GOODS_SUCCESS: ", goodsFiltered);
      return state
        .set("isFetching", false)
        .set("lastGroupPage", lastGroupsPage2)
        .set("totalGroupResultRows", total_results2)
        .set("totalGroupRows", total_rows2)
        .set("entriesPerPageGroupRows", entries_per_page2)
        .set("endReachedGroupPage", false)
        .set("groups", [...groups2])
        .set("groupsFiltered", [...groupsFiltered2]);

    case GET_GROUPS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);


    case SET_GROUPS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_GROUPS_SUCCESS:
      const groups3 = action.payload;
      //console.log("SET_GROUPS_SUCCESS: ", groups3);

      var groupsState3 = state.groups.map((value) => ({ ...value }));
      for (var j = 0; j < groupsState3.length; j++) {
        if (groups3._id === groupsState3[j]._id) {
          groupsState3[j] = { ...groups3 };
        }
      }
      let groupsFiltered3 = [...groupsState3];

      //console.log("SET_GROUPS_SUCCESS: ", goodsFiltered3);
      return state
        .set("isFetching", false)
        .set("groups", [...groupsState3])
        .set("success", "Успешно сохранено")
        .set("groupsFiltered", [...groupsFiltered3]);

    case SET_GROUPS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case ADD_GROUPS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case ADD_GROUPS_SUCCESS:
      const groups4 = action.payload;
      //console.log("ADD_GROUPS_SUCCESS: ", groups4);

      var findItem = false;
      var groupsState4 = state.groups.map((value) => ({ ...value }));
      for (var j = 0; j < groupsState4.length; j++) {
        if (groups4._id === groupsState4[j]._id) {
          groupsState4[j] = { ...groups4 };
          findItem = true;
        }
      }
      if (!findItem) {
        groupsState4.push(groups4);
      }

      let groupsFiltered4 = [...groupsState4];

      //console.log("ADD_GOODS_SUCCESS: ", goodsState10);
      return state
        .set("isFetching", false)
        .set("groups", [...groupsState4])
        .set("success", "Успешно сохранено")
        .set("groupsFiltered", [...groupsFiltered4]);

    case ADD_GROUPS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case GET_GOODS_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_GOODS_SUCCESS:
      //console.log("GET_GOODS_SUCCESS: ", dataobj);
      const goods = action.payload.json;
      const page = action.payload.page;
      const dataobj = action.payload.dataobj;
      const total_results = dataobj.total_results; // in query
      const total_rows = dataobj.total_rows; // in table
      const entries_per_page = dataobj.entries_per_page

      const selectedGroupItemId = action.payload.selectedGroupItemId;
      var selectedGroupItem = undefined;

      var allGroupsState = state.allGroups.map((value) => ({ ...value }));
      for (var j = 0; j < allGroupsState.length; j++) {
        if (selectedGroupItemId === allGroupsState[j]._id) {
          selectedGroupItem = { ...allGroupsState[j] };
        }
      }

      //console.log("GET_GOODS_SUCCESS: ", dataobj);

      let goodsState = [];
      goodsState = goodsState.concat(goods);
      let goodsFiltered = [...goodsState];
      const lastGoodPage = page;

      //console.log("GET_GOODS_SUCCESS: ", goodsFiltered);
      return state
        .set("isFetching", false)
        .set("lastGoodPage", lastGoodPage)
        .set("totalGoodResultRows", total_results)
        .set("totalGoodRows", total_rows)
        .set("entriesPerPageGoodRows", entries_per_page)
        .set("endReachedGoodPage", false)
        .set("selectedGroup", selectedGroupItem)
        .set("goods", [...goods])
        .set("goodsFiltered", [...goodsFiltered]);

    case GET_GOODS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SET_GOODS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_GOODS_SUCCESS:
      const goods9 = action.payload;
      //console.log("SET_GOODS_SUCCESS: ", goods9);

      var goodsState9 = state.goods.map((value) => ({ ...value }));
      for (var j = 0; j < goodsState9.length; j++) {
        if (goods9._id === goodsState9[j]._id) {
          goodsState9[j] = { ...goods9 };
        }
      }
      let goodsFiltered9 = [...goodsState9];

      //console.log("GET_GOODS_SUCCESS: ", goodsFiltered);
      return state
        .set("isFetching", false)
        .set("goods", [...goodsState9])
        .set("success", "Успешно сохранено")
        .set("goodsFiltered", [...goodsFiltered9])
        .set("imageListPaths", []);

    case SET_GOODS_FAILURE:
      return state.set("isFetching", false)
        .set("success", null)
        .set("error", action.payload);


    case ADD_GOODS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case ADD_GOODS_SUCCESS:
      const goods10 = action.payload;
      //console.log("ADD_GOODS_SUCCESS: ", goods10);

      var findItem = false;
      var goodsState10 = state.goods.map((value) => ({ ...value }));
      for (var j = 0; j < goodsState10.length; j++) {
        if (goods10._id === goodsState10[j]._id) {
          goodsState10[j] = { ...goods10 };
          findItem = true;
        }
      }
      if (!findItem) {
        goodsState10.push(goods10);
      }

      let goodsFiltered10 = [...goodsState10];

      //console.log("ADD_GOODS_SUCCESS: ", goodsState10);
      return state
        .set("isFetching", false)
        .set("goods", [...goodsState10])
        .set("success", "Успешно сохранено")
        .set("goodsFiltered", [...goodsFiltered10]);

    case ADD_GOODS_FAILURE:
      return state.set("isFetching", false).set("success", null).set("error", action.payload);


    case GET_GOODS_DETAILS_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case GET_GOODS_DETAILS_SUCCESS:
      const good = action.payload;
      let goodDetails = { ...good };

      const myCart4 = state.myCart;
      for (var j = 0; j < myCart4.length; j++) {
        const index_goods = goodDetails._id === myCart4[j].good_id;
        if (index_goods) {
          if (myCart4[j].qty !== 0.0) {
            // with 0.00 - removed from cart
            goodDetails = {
              ...goodDetails,
              ordered_qty: myCart4[j].qty,
            };
          } else {
            delete goodDetails.ordered_qty;
          }
        }
      }

      let myFavorites = state.myFavorites;
      for (var j = 0; j < myFavorites.length; j++) {
        const index_goods = goodDetails._id === myFavorites[j].good_id;
        if (index_goods) {
          if (myFavorites[j].active === true) {
            goodDetails = {
              ...goodDetails,
              favorites: true,
            };
          } else {
            delete goodDetails.favorites;
          }
        }
      }

      //console.log("GET_GOODS_DETAILS_SUCCESS: ", myFavorites, goodDetails);
      return state
        .set("isFetching", false)
        .set("goodsDetails", { ...goodDetails });

    case GET_GOODS_DETAILS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_PRICELISTS_BY_GOOD_ID_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case GET_PRICELISTS_BY_GOOD_ID_SUCCESS:
      const pricelistsByGood = action.payload;
      console.log("GET_PRICELISTS_BY_GOOD_ID_SUCCESS: ", pricelistsByGood);
      return state
        .set("isFetching", false)
        .set("pricelistsByGood", [...pricelistsByGood]);

    case GET_PRICELISTS_BY_GOOD_ID_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_MY_CART_REQUEST: {
      return state.set("isFetching", true);
    }

    case GET_MY_CART_SUCCESS:
      const _cart = action.payload.json;
      const page1 = action.payload.page;

      if (_cart.length === 0 && page1 !== 0) {
        var next = state
          .set("endReachedCartPage", true)
          .set("isFetching", false);

        return next;
      }

      let myCartState = [];
      if (page1 !== 0) {
        // если первая страница, то сбрасываем массив в 0
        myCartState = state.myCart.map((value) => ({ ...value }));
      }
      myCartState = myCartState.concat(_cart);
      const lastCartPage = _cart.length !== 0 ? page1 + 1 : page1;

      let cart = myCartState.filter((crt) => crt.qty !== 0.0);

      // goods shop
      var goods1 = state.goodsShop.map((value) => ({ ...value }));
      for (var j = 0; j < cart.length; j++) {
        const index_goods = goods1.map((i) => i._id).indexOf(cart[j].good_id);
        if (cart[j].qty !== 0.0) {
          // with 0.00 - removed from cart
          goods1[index_goods] = {
            ...goods1[index_goods],
            ordered_qty: cart[j].qty,
          };
        } else {
          delete goods1[index_goods].ordered_qty;
        }
      }
      var exclusiveGoodsFiltered1 = [...goods1]
        .filter(
          (good) =>
            good.group_id === CONFIG.PGSTORE_CONSTANTS.exclusive_group_id
        )
        .slice(0, 3);
      var bestsellingGoodsFiltered1 = [...goods1]
        .filter(
          (good) =>
            good.group_id === CONFIG.PGSTORE_CONSTANTS.bestselling_group_id
        )
        .slice(0, 3);
      var groceriesGoodsFiltered1 = [...goods1]
        .filter(
          (good) =>
            good.group_id === CONFIG.PGSTORE_CONSTANTS.groceries_group_id
        )
        .slice(0, 3);

      // goods explore
      var goods4 = state.goodsExplore.map((value) => ({ ...value }));
      for (var j = 0; j < cart.length; j++) {
        const index_goods = goods4.map((i) => i._id).indexOf(cart[j].good_id);
        if (cart[j].qty !== 0.0) {
          // with 0.00 - removed from cart
          goods4[index_goods] = {
            ...goods4[index_goods],
            ordered_qty: cart[j].qty,
          };
        } else {
          delete goods4[index_goods].ordered_qty;
        }
      }

      // goods details
      var details = { ...state.goodsDetails };
      if (details) {
        for (var j = 0; j < cart.length; j++) {
          const index_goods = details._id === cart[j].good_id;
          if (index_goods) {
            if (cart[j].qty !== 0.0) {
              // with 0.00 - removed from cart
              details = {
                ...details,
                ordered_qty: cart[j].qty,
              };
            } else {
              delete details.ordered_qty;
            }
          }
        }
      }

      //console.log("GET_MY_CART_SUCCESS: ", cart);
      return state
        .set("isFetching", false)
        .set("myCart", [...cart])
        .set("lastCartPage", lastCartPage)
        .set("endReachedCartPage", false)
        .set("goodsDetails", { ...details })
        .set("goodsShopFiltered", [...goods1])
        .set("goodsExploreFiltered", [...goods4])
        .set("exclusiveGoodsFiltered", [...exclusiveGoodsFiltered1])
        .set("bestsellingGoodsFiltered", [...bestsellingGoodsFiltered1])
        .set("groceriesGoodsFiltered", [...groceriesGoodsFiltered1]);

    case GET_MY_CART_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case ADD_TO_CART_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case ADD_TO_CART_SUCCESS:
      const myCart2 = action.payload;
      //console.log("ADD_TO_CART_SUCCESS: ", myCart2);

      // prepare cart
      var cart2 = state.myCart.map((value) => ({ ...value }));
      const index_cart = cart2.map((i) => i._id).indexOf(myCart2._id);
      if (index_cart !== -1) {
        if (myCart2.qty !== 0.0) {
          cart2[index_cart] = {
            ...cart2[index_cart],
            qty: myCart2.qty,
            price: myCart2.price,
          };
        } else {
          // delete from cart
          cart2.splice(index_cart, 1);
        }
      } else {
        cart2.push({ ...myCart2 });
      }

      // prepare goods shop
      var goods2 = state.goodsShop.map((value) => ({ ...value }));
      for (var j = 0; j < cart2.length; j++) {
        const index_goods = goods2.map((i) => i._id).indexOf(cart2[j].good_id);
        if (index_goods !== -1) {
          if (cart2[j].qty !== 0.0) {
            // with 0.00 - removed from cart
            goods2[index_goods] = {
              ...goods2[index_goods],
              ordered_qty: cart2[j].qty,
            };
          } else {
            delete goods2[index_goods].ordered_qty;
          }
        }
      }
      var exclusiveGoodsFiltered2 = [...goods2]
        .filter(
          (good) =>
            good.group_id === CONFIG.PGSTORE_CONSTANTS.exclusive_group_id
        )
        .slice(0, 3);
      var bestsellingGoodsFiltered2 = [...goods2]
        .filter(
          (good) =>
            good.group_id === CONFIG.PGSTORE_CONSTANTS.bestselling_group_id
        )
        .slice(0, 3);
      var groceriesGoodsFiltered2 = [...goods2]
        .filter(
          (good) =>
            good.group_id === CONFIG.PGSTORE_CONSTANTS.groceries_group_id
        )
        .slice(0, 3);

      // prepare goods explore
      var goods5 = state.goodsExplore.map((value) => ({ ...value }));
      for (var j = 0; j < cart2.length; j++) {
        const index_goods = goods5.map((i) => i._id).indexOf(cart2[j].good_id);
        if (index_goods !== -1) {
          if (cart2[j].qty !== 0.0) {
            // with 0.00 - removed from cart
            goods5[index_goods] = {
              ...goods5[index_goods],
              ordered_qty: cart2[j].qty,
            };
          } else {
            delete goods5[index_goods].ordered_qty;
          }
        }
      }

      // goods details
      var details1 = { ...state.goodsDetails };
      if (details1) {
        const index_goods = details1._id === myCart2.good_id;
        if (index_goods) {
          if (myCart2.qty !== 0.0) {
            // with 0.00 - removed from cart
            details1 = {
              ...details1,
              ordered_qty: myCart2.qty,
            };
          } else {
            delete details1.ordered_qty;
          }
        }
      }

      // + search
      const mySearchGoodsState1 = state.mySearchGoods.map((value) => ({
        ...value,
      }));
      for (var j = 0; j < cart2.length; j++) {
        const index_goods = mySearchGoodsState1
          .map((i) => i._id)
          .indexOf(cart2[j].good_id);
        if (index_goods !== -1) {
          if (cart2[j].qty !== 0.0) {
            // with 0.00 - removed from cart
            mySearchGoodsState1[index_goods] = {
              ...mySearchGoodsState1[index_goods],
              ordered_qty: cart2[j].qty,
            };
          } else {
            delete mySearchGoodsState1[index_goods].ordered_qty;
          }
        }
      }
      let mySearchGoodsFiltered1 = [...mySearchGoodsState1];
      // - search

      //console.log("ADD_TO_CART_SUCCESS: ", details1);

      return state
        .set("isFetching", false)
        .set("myCart", [...cart2])
        .set("goodsDetails", { ...details1 })
        .set("goodsShopFiltered", [...goods2])
        .set("goodsExploreFiltered", [...goods5])
        .set("mySearchGoodsFiltered", [...mySearchGoodsFiltered1])
        .set("exclusiveGoodsFiltered", [...exclusiveGoodsFiltered2])
        .set("bestsellingGoodsFiltered", [...bestsellingGoodsFiltered2])
        .set("groceriesGoodsFiltered", [...groceriesGoodsFiltered2]);

    case ADD_TO_CART_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_FAVORITES_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case GET_FAVORITES_SUCCESS:
      const _favorites = action.payload.json;
      const page4 = action.payload.page;

      if (_favorites.length === 0 && page4 !== 0) {
        var next = state
          .set("endReachedFavoritesPage", true)
          .set("isFetching", false);

        return next;
      }

      let myFavoritesState = [];
      if (page4 !== 0) {
        // если первая страница, то сбрасываем массив в 0
        myFavoritesState = state.myFavorites.map((value) => ({ ...value }));
      }
      myFavoritesState = myFavoritesState.concat(_favorites);
      const lastFavoritesPage = _favorites.length !== 0 ? page4 + 1 : page4;

      let favorites = myFavoritesState.filter((fav) => fav.active === true);

      // // goods shop
      // var goods6 = [...state.goodsShop];
      // for (var j = 0; j < favorites.length; j++) {
      //     const index_goods = goods6.map((i) => i._id).indexOf(favorites[j].good_id);
      //     if (favorites[j].active === true) {
      //         goods6[index_goods] = {
      //             ...goods6[index_goods],
      //             favorites: true,
      //         }
      //     }
      //     else {
      //         delete goods6[index_goods].favorites;
      //     }
      // }
      // var exclusiveGoodsFiltered6 = [...goods6].filter(
      //     (good) =>
      //         good.group_id === CONFIG.PGSTORE_CONSTANTS.exclusive_group_id
      // ).slice(0, 3);
      // var bestsellingGoodsFiltered6 = [...goods6].filter(
      //     (good) =>
      //         good.group_id === CONFIG.PGSTORE_CONSTANTS.bestselling_group_id
      // ).slice(0, 3);
      // var groceriesGoodsFiltered6 = [...goods6].filter(
      //     (good) =>
      //         good.group_id === CONFIG.PGSTORE_CONSTANTS.groceries_group_id
      // ).slice(0, 3);

      // // goods explore
      // var goods7 = [...state.goodsExplore];
      // for (var j = 0; j < favorites.length; j++) {
      //     const index_goods = goods7.map((i) => i._id).indexOf(favorites[j].good_id);
      //     if (favorites[j].active === true) {
      //         goods7[index_goods] = {
      //             ...goods7[index_goods],
      //             favorites: true,
      //         }
      //     }
      //     else {
      //         delete goods7[index_goods].favorites;
      //     }
      // }

      // goods details
      var details2 = { ...state.goodsDetails };
      if (details2) {
        for (var j = 0; j < favorites.length; j++) {
          const index_goods = details2._id === favorites[j].good_id;
          if (index_goods) {
            if (favorites[j].active === true) {
              details2 = {
                ...details2,
                favorites: true,
              };
            } else {
              delete details2.favorites;
            }
          }
        }
      }

      //console.log("GET_MY_CART_SUCCESS: ", goods1, cart);
      return state
        .set("isFetching", false)
        .set("myFavorites", [...favorites])
        .set("lastFavoritesPage", lastFavoritesPage)
        .set("endReachedFavoritesPage", false)
        .set("goodsDetails", { ...details2 });
    //.set('goodsShopFiltered', [...goods6])
    //.set('goodsExploreFiltered', [...goods7])
    //.set('exclusiveGoodsFiltered', [...exclusiveGoodsFiltered6])
    //.set('bestsellingGoodsFiltered', [...bestsellingGoodsFiltered6])
    //.set('groceriesGoodsFiltered', [...groceriesGoodsFiltered6])

    case GET_FAVORITES_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case ADD_TO_FAVORITES_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case ADD_TO_FAVORITES_SUCCESS:
      const favorite1 = action.payload;
      //console.log("ADD_TO_FAVORITES_SUCCESS: ", favorite1);

      // prepare favorites
      var favorites2 = state.myFavorites.map((value) => ({ ...value }));
      const index_favorite = favorites2
        .map((i) => i._id)
        .indexOf(favorite1._id);
      if (index_favorite !== -1) {
        if (favorite1.active === true) {
          favorites2[index_favorite] = {
            ...favorites2[index_favorite],
            active: true,
          };
        } else {
          // delete from favorites
          favorites2.splice(index_favorite, 1);
        }
      } else {
        favorites2.push({ ...favorite1 });
      }

      // goods details
      var details3 = { ...state.goodsDetails };
      if (details3) {
        const index_goods = details3._id === favorite1.good_id;
        if (index_goods) {
          if (favorite1.active === true) {
            details3 = {
              ...details3,
              favorites: true,
            };
          } else {
            // delete from favorites
            delete details3.favorites;
          }
        }
      }

      //console.log("ADD_TO_FAVORITES_SUCCESS: ", favorites2);

      return state
        .set("isFetching", false)
        .set("myFavorites", [...favorites2])
        .set("goodsDetails", { ...details3 });

    case ADD_TO_FAVORITES_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case ADD_TO_ORDERS_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case ADD_TO_ORDERS_SUCCESS:
      const order = action.payload;
      //console.log("ADD_TO_ORDERS_SUCCESS: ", order);

      return state
        .set("isFetching", false)
        .set("order", { ...order })
        .set("myCart", []);

    case ADD_TO_ORDERS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_ORDERS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case GET_ORDERS_SUCCESS:
      const orders = action.payload.json;
      const page10 = action.payload.page;
      const dataobj10 = action.payload.dataobj;
      const total_results10 = dataobj10.total_results; // in query
      const total_rows10 = dataobj10.total_rows; // in table
      const entries_per_page10 = dataobj10.entries_per_page

      console.log("GET_ORDERS_SUCCESS: ", orders);

      const selectedStatusItemId = action.payload.selectedStatusItemId;
      var selectedStatusItem = undefined;

      var allStatusState = state.orderStatus.map((value) => ({ ...value }));
      for (var j = 0; j < allStatusState.length; j++) {
        if (selectedStatusItemId === allStatusState[j].id) {
          selectedStatusItem = { ...allStatusState[j] };
        }
      }

      let ordersState = [];
      ordersState = ordersState.concat(orders);
      let ordersFiltered = [...ordersState];
      const lastGroupsPage10 = page10;

      return state
        .set("isFetching", false)
        .set("lastOrderPage", lastGroupsPage10)
        .set("totalOrderResultRows", total_results10)
        .set("totalOrderRows", total_rows10)
        .set("entriesPerPageOrderRows", entries_per_page10)
        .set("endReachedOrderPage", false)
        .set("selectedStatus", selectedStatusItem)
        .set("orders", [...orders])
        .set("ordersFiltered", [...ordersFiltered]);

    case GET_ORDERS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload).set("success", null);


    case GET_ORDERS_QTY_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case GET_ORDERS_QTY_SUCCESS:
      const orders_qty = action.payload;
      //console.log("GET_ORDERS_QTY_SUCCESS: ", orders_qty);

      return state
        .set("isFetching", false)
        .set("ordersQty", orders_qty);

    case GET_ORDERS_QTY_FAILURE:
      return state.set("isFetching", false).set("error", action.payload).set("success", null);


    case SET_ORDER_STATUS_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case SET_ORDER_STATUS_SUCCESS:
      const order1 = action.payload;
      //console.log("SET_ORDER_STATUS_SUCCESS: ", order1);

      // prepare orders
      var ordersState1 = state.orders.map((value) => ({ ...value }));
      const index_1 = ordersState1
        .map((i) => i._id)
        .indexOf(order1._id);
      if (index_1 !== -1) {
        ordersState1[index_1] = order1;
      } else {
        ordersState1.push({ ...order1 });
      }

      const ordersFiltered1 = ordersState1;
      //console.log("SET_ORDER_STATUS_SUCCESS: ", order1);

      return state
        .set("isFetching", false)
        .set("orders", [...ordersState1])
        .set("success", "Успешно сохранено")
        .set("ordersFiltered", [...ordersFiltered1]);

    case SET_ORDER_STATUS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case DELETE_USER_DATA_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case DELETE_USER_DATA_SUCCESS:
      const datas = action.payload;
      //console.log("DELETE_USER_DATA_SUCCESS: ", datas);
      return state.set("isFetching", false).set("deletedUserData", datas);

    case DELETE_USER_DATA_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SEARCH_GOODS_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case SEARCH_GOODS_SUCCESS:
      //console.log("SEARCH_GOODS_SUCCESS: ", action.payload);
      const goods8 = action.payload.json;
      const page8 = action.payload.page;

      if (goods8.length === 0 && page8 !== 0) {
        var next = state
          .set("endReachedSearchGoodsPage", true)
          .set("isFetching", false);

        return next;
      }

      let mySearchGoodsState = [];
      if (page8 !== 0) {
        // если первая страница, то сбрасываем массив в 0
        mySearchGoodsState = state.mySearchGoods.map((value) => ({ ...value }));
      }
      mySearchGoodsState = mySearchGoodsState.concat(goods8);
      let mySearchGoodsFiltered = [...mySearchGoodsState];
      const lastSearchGoodsPage = goods8.length !== 0 ? page8 + 1 : page8;

      const myCart5 = state.myCart;
      for (var j = 0; j < myCart5.length; j++) {
        const index_goods = mySearchGoodsFiltered
          .map((i) => i._id)
          .indexOf(myCart5[j].good_id);
        if (myCart5[j].qty !== 0.0) {
          // with 0.00 - removed from cart
          mySearchGoodsFiltered[index_goods] = {
            ...mySearchGoodsFiltered[index_goods],
            ordered_qty: myCart5[j].qty,
          };
        } else {
          delete mySearchGoodsFiltered[index_goods].ordered_qty;
        }
      }

      //console.log("SEARCH_GOODS_SUCCESS: ", mySearchGoodsFiltered);
      return state
        .set("isFetching", false)
        .set("lastSearchGoodsPage", lastSearchGoodsPage)
        .set("endReachedSearchGoodsPage", false)
        .set("mySearchGoods", [...mySearchGoodsState])
        .set("mySearchGoodsFiltered", [...mySearchGoodsFiltered]);

    case SEARCH_GOODS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_MESSAGES_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case GET_MESSAGES_SUCCESS:
      const _messages = action.payload.json;
      const page5 = action.payload.page;
      const dataobj5 = action.payload.dataobj;
      const total_results5 = dataobj5.total_results; // in query
      const total_rows5 = dataobj5.total_rows; // in table
      const entries_per_page5 = dataobj5.entries_per_page

      const selectedUserItemId = action.payload.selectedUserItemId;
      var selectedUserItem = undefined;

      var allUsersState = state.allUsers.map((value) => ({ ...value }));
      for (var j = 0; j < allUsersState.length; j++) {
        if (selectedUserItemId === allUsersState[j]._id) {
          selectedUserItem = { ...allUsersState[j] };
        }
      }

      let messagesState = [];
      messagesState = messagesState.concat(_messages);
      const lastMessagesPage = page5;

      for (var i = 0; i < messagesState.length; i++) {
        var message = messagesState[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        messagesState[i] = {
          ...messagesState[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }
      var messagesFiltered = messagesState.sort((a, b) => b.dateDT - a.dateDT);

      //console.log("GET_MESSAGES_SUCCESS: ", _messages);
      return state
        .set("isFetching", false)
        .set("lastMessagesPage", lastMessagesPage)
        .set("totalMessagesResultRows", total_results5)
        .set("totalMessagesRows", total_rows5)
        .set("entriesPerPageMessagesRows", entries_per_page5)
        .set("endReachedMessagesPage", false)
        .set("selectedMessageUser", selectedUserItem)
        .set("messages", [...messagesState])
        .set("messagesFiltered", [...messagesFiltered]);

    case GET_MESSAGES_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SET_MESSAGE_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_MESSAGE_SUCCESS:
      const message1 = action.payload;
      //console.log("SET_MESSAGE_SUCCESS: ", message1);

      // prepare messages
      var messagesState1 = state.messages.map((value) => ({ ...value }));
      const index_message1 = messagesState1
        .map((i) => i._id)
        .indexOf(message1._id);
      if (index_message1 !== -1) {
        messagesState1[index_message1] = message1;
      } else {
        messagesState1.push({ ...message1 });
      }

      for (var i = 0; i < messagesState1.length; i++) {
        var message = messagesState1[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        messagesState1[i] = {
          ...messagesState1[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }
      var messagesFiltered2 = messagesState1.sort((a, b) => b.dateDT - a.dateDT);

      return state
        .set("isFetching", false)
        .set("messages", [...messagesState1])
        .set("success", "Успешно сохранено")
        .set("messagesFiltered", [...messagesFiltered2]);

    case SET_MESSAGE_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case LOGOUT_CLEAR_SUCCESS:
      //console.log("LOGOUT_CLEAR_SUCCESS");
      return (
        state
          .set("isFetching", false)
          .set("areas", [])
          .set("areasDropDownItems", [])
          .set("timeSlots", [])
          .set("selectedTimeSlot", null)
          .set("paymentMethods", [])
          .set("deliveryVariants", [])
          .set("order", null)
          .set("warehouses", [])
          //.set('groupsShop', [])
          //.set('groupsShopFiltered', [])
          //.set('groupsExplore', [])
          //.set('groupsExploreFiltered', [])
          //.set('goodsShop', [])
          //.set('goodsShopFiltered', [])
          //.set('lastGoodShopPage', 0)
          //.set('endReachedGoodShopPage', false)
          //.set('exclusiveGoodsFiltered', [])
          //.set('bestsellingGoodsFiltered', [])
          //.set('groceriesGoodsFiltered', [])
          //.set('goodsExplore', [])
          //.set('goodsExploreFiltered', [])
          //.set('lastGoodExplorePage', 0)
          //.set('endReachedGoodExplorePage', false)
          //.set('goodsDetails', null)
          .set("myCart", [])
          .set("lastCartPage", 0)
          .set("endReachedCartPage", false)
          .set("myFavorites", [])
          .set("lastFavoritesPage", 0)
          .set("endReachedFavoritesPage", false)
          .set("myOrders", [])
          .set("lastOrdersPage", 0)
          .set("endReachedOrdersPage", false)
          .set("deletedUserData", null)
          .set("mySearchGoods", [])
          .set("mySearchGoodsFiltered", [])
          .set("lastSearchGoodsPage", 0)
          .set("endReachedSearchGoodsPage", false)
          .set("messages", [])
          .set("messagesFiltered", [])
          .set("lastMessagesPage", 0)
          .set("endReachedMessagesPage", false)
          .set("chat", [])
          .set("chatFiltered", [])
          .set("lastChatPage", 0)
          .set("endReachedChatPage", false)
      );

    case GET_CHAT_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case GET_CHAT_SUCCESS:
      const _chat = action.payload.json;
      const page6 = action.payload.page;
      const dataobj6 = action.payload.dataobj;
      const total_results6 = dataobj6.total_results; // in query
      const total_rows6 = dataobj6.total_rows; // in table
      const entries_per_page6 = dataobj6.entries_per_page

      const selectedUserItemId1 = action.payload.selectedUserItemId;
      var selectedUserItem1 = undefined;

      var allUsersState = state.allUsers.map((value) => ({ ...value }));
      for (var j = 0; j < allUsersState.length; j++) {
        if (selectedUserItemId1 === allUsersState[j]._id) {
          selectedUserItem1 = { ...allUsersState[j] };
        }
      }

      let chatState = [];
      chatState = chatState.concat(_chat);
      const lastChatPage = page6;

      for (var i = 0; i < chatState.length; i++) {
        var message = chatState[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        chatState[i] = {
          ...chatState[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }
      var chatFiltered = chatState.sort((a, b) => b.dateDT - a.dateDT);

      console.log("GET_CHAT_SUCCESS: ", dataobj6);
      return state
        .set("isFetching", false)
        .set("lastChatPage", lastChatPage)
        .set("totalChatResultRows", total_results6)
        .set("totalChatRows", total_rows6)
        .set("entriesPerPageChatRows", entries_per_page6)
        .set("endReachedChatPage", false)
        .set("selectedChatUser", selectedUserItem1)
        .set("chat", [...chatState])
        .set("chatFiltered", [...chatFiltered]);

    case GET_CHAT_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SET_CHAT_READ_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case SET_CHAT_READ_SUCCESS:
      const _chat11 = action.payload.json;
      const page11 = action.payload.page;
      const dataobj11 = action.payload.dataobj;
      const total_results11 = dataobj11.total_results; // in query
      const total_rows11 = dataobj11.total_rows; // in table
      const entries_per_page11 = dataobj11.entries_per_page

      const selectedUserItemId11 = action.payload.selectedUserItemId;
      var selectedUserItem11 = undefined;

      var allUsersState11 = state.allUsers.map((value) => ({ ...value }));
      for (var j = 0; j < allUsersState11.length; j++) {
        if (selectedUserItemId11 === allUsersState11[j]._id) {
          selectedUserItem11 = { ...allUsersState11[j] };
        }
      }

      let chatState11 = [];
      chatState11 = chatState11.concat(_chat11);
      const lastChatPage11 = page11;

      for (var i = 0; i < chatState11.length; i++) {
        var message = chatState11[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        chatState11[i] = {
          ...chatState11[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }
      var chatFiltered11 = chatState11.sort((a, b) => b.dateDT - a.dateDT);

      console.log("GET_CHAT_SUCCESS: ", dataobj11);
      return state
        .set("isFetching", false)
        .set("lastChatPage", lastChatPage11)
        .set("totalChatResultRows", total_results11)
        .set("totalChatRows", total_rows11)
        .set("entriesPerPageChatRows", entries_per_page11)
        .set("endReachedChatPage", false)
        .set("selectedChatUser", selectedUserItem11)
        .set("chat", [...chatState11])
        .set("chatFiltered", [...chatFiltered11]);

    case SET_CHAT_READ_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case ADD_TO_CHAT_REQUEST:
      return state.set("isFetching", true);

    case ADD_TO_CHAT_SUCCESS:
      const chat2 = action.payload;
      //console.log("ADD_TO_CHAT_SUCCESS: ", chat2);

      // prepare chat
      let chatState2 = state.chat.map((value) => ({ ...value }));
      const index_chat1 = chatState2.map((i) => i._id).indexOf(chat2._id);
      if (index_chat1 !== -1) {
        chatState2[index_chat1] = { ...chat2 };
      } else {
        chatState2.push({ ...chat2 });
      }

      for (var i = 0; i < chatState2.length; i++) {
        var message = chatState2[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        chatState2[i] = {
          ...chatState2[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }

      var chatFiltered3 = chatState2.sort((a, b) => b.dateDT - a.dateDT);

      return state
        .set("isFetching", false)
        .set("chat", [...chatState2])
        .set("chatFiltered", [...chatFiltered3]);

    case ADD_TO_CHAT_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);


    case ADD_MESSAGE_REQUEST:
      return state.set("isFetching", true);

    case ADD_MESSAGE_SUCCESS:
      const message2 = action.payload;
      //console.log("ADD_TO_CHAT_SUCCESS: ", chat2);

      // prepare chat
      let messageState2 = state.messages.map((value) => ({ ...value }));
      const index_2 = messageState2.map((i) => i._id).indexOf(message2._id);
      if (index_2 !== -1) {
        messageState2[index_2] = { ...message2 };
      } else {
        messageState2.push({ ...message2 });
      }

      for (var i = 0; i < messageState2.length; i++) {
        var message = messageState2[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        messageState2[i] = {
          ...messageState2[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }
      var messageFiltered2 = messageState2.sort((a, b) => b.dateDT - a.dateDT);

      return state
        .set("isFetching", false)
        .set("messages", [...messageState2])
        .set("success", "Успешно сохранено")
        .set("messagesFiltered", [...messageFiltered2]);

    case ADD_MESSAGE_FAILURE:
      return state.set("isFetching", false).set("error", action.payload).set("success", null);


    case SET_CHAT_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_CHAT_SUCCESS:
      const chat3 = action.payload;
      //console.log("SET_CHAT_SUCCESS: ", chat3);

      // prepare messages
      var chatState3 = state.chat.map((value) => ({ ...value }));
      const index_chat3 = chatState3
        .map((i) => i._id)
        .indexOf(chat3._id);
      if (index_chat3 !== -1) {
        chatState3[index_chat3] = chat3;
      } else {
        chatState3.push({ ...chat3 });
      }

      for (var i = 0; i < chatState3.length; i++) {
        var message = chatState3[i];

        const formatedDate = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        ).toLocaleDateString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        const dateDT = new Date(
          message.date_ts[0],
          message.date_ts[1],
          message.date_ts[2],
          message.date_ts[3],
          message.date_ts[4],
          message.date_ts[5],
          message.date_ts[6]
        );

        chatState3[i] = {
          ...chatState3[i],
          formatedDate: formatedDate,
          dateDT: dateDT,
        };
      }
      var chatFiltered3 = chatState3.sort((a, b) => b.dateDT - a.dateDT);

      return state
        .set("isFetching", false)
        .set("chat", [...chatState3])
        .set("success", "Успешно сохранено")
        .set("chatFiltered", [...chatFiltered3]);

    case SET_CHAT_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_DASHBOARD_QTY_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null).set("error", "");
      return nextState;
    }

    case GET_DASHBOARD_QTY_SUCCESS:
      const total_num_orders = action.payload.total_num_orders;
      const total_num_goods = action.payload.total_num_goods;
      const total_num_users = action.payload.total_num_users;
      const total_num_in_carts = action.payload.total_num_in_carts;

      //console.log("GET_DASHBOARD_QTY_SUCCESS: ", action.payload);

      return state
        .set("isFetching", false)
        .set("total_num_orders", total_num_orders)
        .set("total_num_goods", total_num_goods)
        .set("total_num_users", total_num_users)
        .set("total_num_in_carts", total_num_in_carts);

    case GET_DASHBOARD_QTY_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SET_UPLOAD_IMAGE_REQUEST: {
      let nextState = state.set("isFetching", true);
      return nextState;
    }

    case SET_UPLOAD_IMAGE_SUCCESS:
      const image_paths = action.payload;

      //console.log("SET_UPLOAD_IMAGE_SUCCESS: ", image_paths);
      return state
        .set("isFetching", false)
        .set("imageListPaths", [...image_paths]);

    case SET_UPLOAD_IMAGE_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case GET_BANNERS_REQUEST: {
      let nextState = state.set("isFetching", true)
        .set("success", null)
        .set("error", "");
      return nextState;
    }

    case GET_BANNERS_SUCCESS:
      const _banners = action.payload.json;
      const page13 = action.payload.page;
      const dataobj13 = action.payload.dataobj;
      const total_results13 = dataobj13.total_results; // in query
      const total_rows13 = dataobj13.total_rows; // in table
      const entries_per_page13 = dataobj13.entries_per_page

      let bannersState = [];
      bannersState = bannersState.concat(_banners);
      let bannersFiltered = [...bannersState];
      const lastBannersPage = page13;

      bannersFiltered = bannersFiltered.sort((a, b) => b.banner_date - a.banner_date);

      //console.log("GET_BANNERS_SUCCESS: ", bannersFiltered);
      return state
        .set("isFetching", false)
        .set("lastBannersPage", lastBannersPage)
        .set("totalBannersResultRows", total_results13)
        .set("totalBannersRows", total_rows13)
        .set("entriesPerPageBannersRows", entries_per_page13)
        .set("endReachedBannersPage", false)
        .set("banners", [...bannersState])
        .set("bannersFiltered", [...bannersFiltered]);

    case GET_BANNERS_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);

    case SET_BANNER_REQUEST: {
      let nextState = state.set("isFetching", true).set("success", null)
        .set("error", "");
      return nextState;
    }

    case SET_BANNER_SUCCESS:
      const banner1 = action.payload;
      //console.log("SET_BANNER_SUCCESS: ", banner1);

      // prepare messages
      var bannerState1 = state.banners.map((value) => ({ ...value }));
      const index_message2 = bannerState1
        .map((i) => i._id)
        .indexOf(banner1._id);
      if (index_message2 !== -1) {
        bannerState1[index_message2] = banner1;
      } else {
        bannerState1.push({ ...banner1 });
      }

      let bannersFiltered2 = [...bannerState1];
      bannersFiltered2 = bannersFiltered2.sort((a, b) => b.banner_date - a.banner_date);

      return state
        .set("isFetching", false)
        .set("banners", [...bannerState1])
        .set("success", "Успешно сохранено")
        .set("bannersFiltered", [...bannersFiltered2]);

    case SET_BANNER_FAILURE:
      return state.set("isFetching", false).set("error", action.payload);


    case ADD_BANNER_REQUEST:
      return state.set("isFetching", true);

    case ADD_BANNER_SUCCESS:
      const banner2 = action.payload;
      //console.log("ADD_BANNER_SUCCESS: ", banner2);

      // prepare chat
      let bannerState2 = state.banners.map((value) => ({ ...value }));
      const index_3 = bannerState2.map((i) => i._id).indexOf(banner2._id);
      if (index_3 !== -1) {
        bannerState2[index_3] = { ...banner2 };
      } else {
        bannerState2.push({ ...banner2 });
      }

      let bannersFiltered3 = [...bannerState2];
      bannersFiltered3 = bannersFiltered3.sort((a, b) => b.banner_date - a.banner_date);

      return state
        .set("isFetching", false)
        .set("banners", [...bannerState2])
        .set("success", "Успешно сохранено")
        .set("bannersFiltered", [...bannersFiltered3]);

    case ADD_BANNER_FAILURE:
      return state.set("isFetching", false).set("error", action.payload).set("success", null);





    // case REHYDRATE:
    //   return {
    //     ...state,
    //   };
  }

  return state;
}
