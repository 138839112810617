// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyA2P7-eoEB9N8I11zy8AgWorwojAAmfLAU",
    authDomain: "pgstoreadmin.firebaseapp.com",
    projectId: "pgstoreadmin",
    storageBucket: "pgstoreadmin.appspot.com",
    messagingSenderId: "727850762961",
    appId: "1:727850762961:web:1f3e826bfa9d88e50fbbf0",
    measurementId: "G-7QKN75GJPV",
  };