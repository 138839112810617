/**
 * # PGStoreApi.js
 *
 * This class interfaces with parse-server using the rest api
 *
 *
 */
"use strict";

/**
 * ## Imports
 *
 */

import CONFIG from "../config/config";
import _ from "underscore";
import APIBackend from "./APIBackend";

//const axios = require('axios').default;
import axios from 'axios';

export class PGStoreApi extends APIBackend {
  /**
   * ## PGStoreApi.js client
   *
   *
   * @throws tokenMissing if token is undefined
   */
  initialize(token) {
    if (!_.isNull(token) && _.isUndefined(token.sessionToken)) {
      throw new Error("TokenMissing");
    }
    this._sessionToken = _.isNull(token) ? null : token.sessionToken;

    //console.log('PGStoreApi this._sessionToken: ', this._sessionToken);
  }

  /**
   * ### check_client_token
   *
   * @param data object
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async check_client_token(data) {
    //console.log("check_client_token API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.check_client_token,
        body: {
          token: data.token,
        },
      },
      "GET"
    )

      .then((response) => {
        console.log("check_client_token API: ", response);
        return response;
      })

      .catch((error) => {
        console.log("check_client_token API error! ", error);
        throw error;
      });
  }

  /**
   * ### check_or_create_user
   *
   * @param data object
   *
   * token
   * firebase_uid
   * fcm_uid
   * device_uid
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async check_or_create_user(data) {
    //console.log("check_or_create_user API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.create_new_user,
        body: {
          firebase_uid: data.firebase_uid,
          fcm_uid: data.fcm_uid,
          device_uid: data.device_uid,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("check_or_create_user API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log(
          "check_or_create_user API error! ",
          error,
          error.code,
          error.status
        );
        throw error;
      });
  }

  /**
   * ### get_users
   *
   * @param data object
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_users(data) {
    //console.log("get_users API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_users,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_users API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_users API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_user_info
   *
   * @param data object
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_user_info(data) {
    //console.log("get_user_info API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_user_info,
        params: {
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_user_info API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_user_info API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_areas
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_areas(data = null) {
    //console.log("get_areas API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_areas,
        params: {
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "GET"
    )

      .then((response) => {
        console.log("get_areas API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_areas API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_warehouses
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_warehouses(data = null) {
    //console.log("get_warehouses API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_warehouses,
        params: {
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_areas API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_warehouses API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_time_slots
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_time_slots(data) {
    //console.log("get_time_slots API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_time_slots,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_time_slots API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_time_slots API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_time_slots
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_time_slots(data) {
    //console.log("set_time_slots API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_time_slots_web,
        body: {
          time_slot_id: data.time_slot_id,
          TimeStart: data.TimeStart,
          TimeEnd: data.TimeEnd,
          Desc: data.Desc,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_time_slots API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_time_slots API error! ", error);
        throw error;
      });
  }

  /**
   * ### add_time_slots
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_time_slots(data) {
    //console.log("add_time_slots API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.new_time_slots_web,
        body: {
          TimeStart: data.TimeStart,
          TimeEnd: data.TimeEnd,
          Desc: data.Desc,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_time_slots API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_time_slots API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_payment_methods
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_payment_methods(data) {
    //console.log("get_payment_methods API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_payment_methods,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_payment_methods API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_payment_methods API error! ", error);
        throw error;
      });
  }

  /**
   * ### set_payment_methods
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_payment_methods(data) {
    //console.log("set_payment_methods API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_payment_methods_web,
        body: {
          payment_method_id: data.payment_method_id,
          desc: data.desc,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_payment_methods API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_payment_methods API error! ", error);
        throw error;
      });
  }

  /**
   * ### add_payment_methods
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_payment_methods(data) {
    //console.log("add_payment_methods API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.new_payment_methods_web,
        body: {
          desc: data.desc,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_payment_methods API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_payment_methods API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_delivery_variants
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_delivery_variants(data) {
    //console.log("get_delivery_variants API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_delivery_variants,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_delivery_variants API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_delivery_variants API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_delivery_variants
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_delivery_variants(data) {
    //console.log("set_delivery_variants API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_delivery_variants_web,
        body: {
          delivery_variant_id: data.delivery_variant_id,
          desc: data.desc,
          price: data.price,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_delivery_variants API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_delivery_variants API error! ", error);
        throw error;
      });
  }


  /**
   * ### add_delivery_variants
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_delivery_variants(data) {
    //console.log("add_delivery_variants API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.new_delivery_variants_web,
        body: {
          desc: data.desc,
          price: data.price,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_delivery_variants API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_delivery_variants API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_price_types
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_price_types(data) {
    //console.log("get_price_types API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_price_types,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_price_types API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_price_types API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_price_types
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_price_types(data) {
    //console.log("set_price_types API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_price_types_web,
        body: {
          price_type_id: data.price_type_id,
          desc: data.desc,
          percent_info: data.percent_info,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_price_types API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_price_types API error! ", error);
        throw error;
      });
  }


  /**
   * ### add_price_types
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_price_types(data) {
    //console.log("add_price_types API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.new_price_types_web,
        body: {
          desc: data.desc,
          percent_info: data.percent_info,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_price_types API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_price_types API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_groups
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_groups(data) {
    //console.log("get_groups API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_groups,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_groups API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_groups API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_all_groups
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_all_groups(data) {
    //console.log("get_all_groups API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_all_groups,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_all_groups API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_all_groups API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_all_price_types
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_all_price_types(data) {
    //console.log("get_all_price_types API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_all_price_types,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_all_price_types API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_all_price_types API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_all_users
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_all_users(data) {
    //console.log("get_all_users API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_all_users,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_all_users API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_all_users API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_goods
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_goods(data) {
    //console.log("get_goods API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_goods_web,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_goods API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_goods API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_good_by_id
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_good_by_id(data) {
    //console.log("get_good_by_id API: ", data);
    const { id } = data.params;
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_good_by_id + id,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_good_by_id API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_good_by_id API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_goods
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_goods(data) {
    //console.log("set_goods API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_goods_web,
        body: {
          good_id: data.good_id,
          Article: data.Article,
          Name: data.Name,
          nutritions: data.nutritions ? data.nutritions : "",
          desc: data.desc ? data.desc : "",
          group_id: data.group_id,
          warehouse_id: data.warehouse_id,
          Barcode: data.Barcode,
          in_stock: data.in_stock ? data.in_stock : 0.00,
          multiplicity: data.multiplicity,
          nutritions_qty: data.nutritions_qty,
          country: data.country,
          imgUrl: data.imgUrl,
          price: data.price,
          price_info: data.price_info,
          isWeight: data.isWeight,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_goods API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_goods API error! ", error);
        throw error;
      });
  }


  /**
   * ### add_goods
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_goods(data) {
    //console.log("add_goods API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.new_goods_web,
        body: {
          Article: data.Article,
          Name: data.Name,
          nutritions: data.nutritions ? data.nutritions : "",
          desc: data.desc ? data.desc : "",
          group_id: data.group_id,
          warehouse_id: data.warehouse_id,
          Barcode: data.Barcode,
          in_stock: data.in_stock ? data.in_stock : 0.00,
          multiplicity: data.multiplicity,
          nutritions_qty: data.nutritions_qty,
          country: data.country,
          imgUrl: data.imgUrl,
          price: data.price,
          price_info: data.price_info,
          isWeight: data.isWeight,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_goods API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_goods API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_group_by_id
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_group_by_id(data) {
    //console.log("get_group_by_id API: ", data);
    const { id } = data.params;
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_group_by_id + id,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_group_by_id API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_group_by_id API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_groups
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_groups(data) {
    //console.log("set_groups API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_groups_web,
        body: {
          group_id: data.group_id,
          name: data.name,
          color: data.color ? data.color : "#F2F3F2",
          isExplore: data.isExplore,
          imgUrl: data.imgUrl,
          borderColor: data.borderColor ? data.borderColor : "#000000",
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_groups API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_groups API error! ", error);
        throw error;
      });
  }


  /**
   * ### add_groups
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_groups(data) {
    //console.log("add_groups API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.new_groups_web,
        body: {
          name: data.name,
          color: data.color ? data.color : "#ffffff",
          isExplore: data.isExplore,
          imgUrl: data.imgUrl,
          borderColor: data.borderColor ? data.borderColor : "#000000",
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_groups API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_groups API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_cart
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_cart(data) {
    //console.log("get_cart API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_cart,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_cart API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_cart API error! ", error);
        throw error;
      });
  }

  /**
   * ### add_to_cart
   *
   * @param data object
   *
   * good_id
   * qty
   * price
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_to_cart(data) {
    //console.log("add_to_cart API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.add_to_cart,
        body: {
          good_id: data.good_id,
          qty: data.qty,
          price: data.price,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_to_cart API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_to_cart API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_favorites
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_favorites(data) {
    //console.log("get_favorites API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_favorites,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_favorites API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_favorites API error! ", error);
        throw error;
      });
  }

  /**
   * ### add_to_favorites
   *
   * @param data object
   *
   * good_id
   * qty
   * price
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_to_favorites(data) {
    //console.log("add_to_favorites API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.add_to_favorites,
        body: {
          good_id: data.good_id,
          active: data.active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_to_favorites API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_to_favorites API error! ", error);
        throw error;
      });
  }

  /**
   * ### add_to_orders
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_to_orders(data) {
    //console.log("add_to_orders API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.add_to_orders,
        body: {
          phone: data.phone,
          address: data.address,
          date_delivery: data.date_delivery,
          time_slot: data.time_slot,
          delivery_variant: data.delivery_variant,
          payment_method: data.payment_method,
          order_lines: data.cart,
          amount: data.amount,
          delivery_amount: data.delivery_amount,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_to_orders API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_to_orders API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_orders
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_orders(data) {
    //console.log("get_orders API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_orders,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_orders API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_orders API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_orders
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_orders_qty() {
    //console.log("get_orders_qty API: ", data);
    const params = {
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_orders_qty,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_orders_qty API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_orders_qty API error! ", error);
        throw error;
      });
  }

  /**
   * ### update_order_status
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async update_order_status(data) {
    //console.log("update_order_status API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_order_status,
        body: {
          order_id: data.order_id,
          new_status: data.new_status,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("update_order_status API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("update_order_status API error! ", error);
        throw error;
      });
  }

  /**
   * ### delete_user_data
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async delete_user_data(data) {
    //console.log("delete_user_data API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.delete_user_data,
        params: params,
      },
      "DELETE"
    )

      .then((response) => {
        //console.log("delete_user_data API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("delete_user_data API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_messages
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_messages(data) {
    //console.log("get_messages API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_messages,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_messages API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_messages API error! ", error);
        throw error;
      });
  }


  /**
   * ### add_new_message
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_new_message(data) {
    //console.log("add_new_message API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.add_new_message,
        body: {
          user_id: data.user_id,
          message_text: data.message_text,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_new_message API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_new_message API error! ", error);
        throw error;
      });
  }

  /**
   * ### set_message
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_message(data) {
    //console.log("set_message API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_message,
        body: {
          message_id: data.message_id,
          message_text: data.message_text,
          active: data.active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("message_text API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("message_text API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_chat
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_chat(data) {
    //console.log("get_chat API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_chat,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_chat API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_chat API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_chat
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_chat(data) {
    //console.log("set_chat API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_chat,
        body: {
          chat_id: data.chat_id,
          message_text: data.message_text,
          active: data.active,
          is_reply: data.is_reply,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_chat API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_chat API error! ", error);
        throw error;
      });
  }

  /**
   * ### set_chat_read
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_chat_read(data) {
    //console.log("set_chat_read API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_chat_read,
        body: {
          user_id: data.user_id,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_chat_read API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_chat_read API error! ", error);
        throw error;
      });
  }

  /**
   * ### add_to_chat
   *
   * @param data object
   *
   * message_text
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_to_chat(data) {
    //console.log("add_to_chat API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.add_to_chat,
        body: {
          user_id: data.user_id,
          message_text: data.message_text,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_to_chat API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_to_chat API error! ", error);
        throw error;
      });
  }

  /**
   * ### update_user
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async update_user(data) {
    var params = {
      user_id: data.user_id,
      phone: data.phone,
      address: data.address,
      price_type_id: data.price_type_id,
      app_name: CONFIG.PGSTORE_API.app_name,
    };
    if (data.tg_uid) {
      params = {
        ...params,
        tg_uid: data.tg_uid,
      }
    }
    if (data.user_area) {
      params = {
        ...params,
        user_area: data.user_area,
      }
    }
    if (data.user_warehouse) {
      params = {
        ...params,
        user_warehouse: data.user_warehouse,
      }
    }
    console.log("update_user API: ", params);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.update_user,
        body: {
          ...params,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("update_user API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("update_user API error! ", error);
        throw error;
      });
  }

  /**
   * ### get_dashboard_qty
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_dashboard_qty(data) {
    //console.log("get_messages API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_dashboard_qty,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_dashboard_qty API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_dashboard_qty API error! ", error);
        throw error;
      });
  }



  /**
   * ### get_pricelists_by_good_id
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_pricelists_by_good_id(data) {
    //console.log("get_pricelists_by_good_id API: ", data);
    const { id } = data.params;
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_pricelists_by_good_id + id,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_pricelists_by_good_id API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_pricelists_by_good_id API error! ", error);
        throw error;
      });
  }


  /**
   * ### set_upload_image
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_upload_image(data) {
    //console.log("set_upload_image API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_upload_image,
        body: {
          image_list: data.imageList,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        console.log("set_upload_image API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_upload_image API error! ", error);
        throw error;
      });
  }


  /**
   * ### get_banners
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async get_banners(data) {
    //console.log("get_banners API: ", data);
    const params = {
      ...data.params,
      app_name: CONFIG.PGSTORE_API.app_name,
    };

    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.get_banners,
        params: params,
      },
      "GET"
    )

      .then((response) => {
        //console.log("get_banners API: ", response);
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("get_banners API error! ", error);
        throw error;
      });
  }


  /**
   * ### add_new_banner
   *
   * @param data object
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async add_new_banner(data) {
    //console.log("add_new_banner API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.add_new_banner,
        body: {
          capition: data.capition,
          html_desc: data.html_desc,
          img: data.img,
          link: data.link,
          link_text: data.link_text,
          banner_date: data.banner_date,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("add_new_banner API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("add_new_banner API error! ", error);
        throw error;
      });
  }

  /**
   * ### set_banner
   *
   * @param data object
   *
   *
   *
   * @return
   * if ok, res.json={...}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async set_banner(data) {
    //console.log("set_banner API: ", data);
    return await this._fetch(
      {
        url: CONFIG.PGSTORE_API.set_banner,
        body: {
          banner_id: data.banner_id,
          capition: data.capition,
          html_desc: data.html_desc,
          img: data.img,
          link: data.link,
          link_text: data.link_text,
          banner_date: data.banner_date,
          Active: data.Active,
          app_name: CONFIG.PGSTORE_API.app_name,
        },
      },
      "POST"
    )

      .then((response) => {
        //console.log("set_banner API: ", JSON.parse(JSON.stringify(response)));
        return JSON.parse(JSON.stringify(response));
      })

      .catch((error) => {
        console.log("set_banner API error! ", error);
        throw error;
      });
  }




  //--------------- FETCH ---------------

  /**
   * ### _fetch
   * A generic function that prepares the request
   *
   * @returns object:
   *  {code: response.code,
   *   status: response.status,
   *   json: response.json()
   */
  async _fetch(opts, method = "POST") {
    opts = _.extend(
      {
        method: method,
        url: null,
        body: null,
        data: null,
        params: null,
        callback: null,
        uid: null,
      },
      opts
    );

    var reqOpts = {
      method: opts.method,
      timeout: 10000,
      headers: {},
    };

    reqOpts.headers["Authorization"] = this._sessionToken;
    reqOpts.headers["Accept"] = "application/json";
    reqOpts.headers["Content-Type"] = "application/json";
    reqOpts.headers["app"] = CONFIG.PGSTORE_API.app_name;

    if (opts.body) {
      reqOpts.body = JSON.stringify(opts.body);
    }
    if (opts.body) {
      reqOpts.data = JSON.stringify(opts.body);
    }
    if (opts.params) {
      reqOpts.params = opts.params;
    }

    let url = opts.url;

    //console.log("PGSTORE fetch reqOpts: ", url);

    //return await fetch(url, reqOpts)
    if (method === "POST") {
      return await axios
        .post(url, reqOpts.data, reqOpts)
        .then((response) => {
          //console.log("axios response: ", response);
          return response;
        })
        .catch((error) => {
          console.log("axios post fail with error! ", error);
          throw error;
        });
    } else {
      if (method === "DELETE") {
        return await axios
          .delete(url, reqOpts)
          .then((response) => {
            //console.log("axios response: ", response);
            return response;
          })
          .catch((error) => {
            console.log("axios delete fail with error! ", error);
            throw error;
          });
      } else {
        return await axios
          .get(url, reqOpts)
          .then((response) => {
            //console.log("axios response: ", response);
            return response;
          })
          .catch((error) => {
            console.log("axios fail with error! ", error);
            throw error;
          });
      }
    }
  }
}
// The singleton variable
export let pGStoreApi = new PGStoreApi();