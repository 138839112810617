import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Select_company = React.lazy(() => import("./views/pages/select_company/Select_company"));
const Registred = React.lazy(() => import("./views/pages/register/Registred"));
const Email_send = React.lazy(() => import("./views/pages/forgot_password/Email_send"));
const Forgot_password = React.lazy(() => import("./views/pages/forgot_password/Forgot_password"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Login" element={<Login />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/Select_company" name="Select company" element={<Select_company />} />
            <Route exact path="/Registred" name="Registred" element={<Registred />} />
            <Route exact path="/Email_send" name="Email send" element={<Email_send />} />
            <Route exact path="/Forgot_password" name="Forgot password" element={<Forgot_password />} />
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
